import { scaleLinear } from '@visx/scale';
import { useMemo } from 'react';

import useAssetClassBreakdown from 'api/hooks/home/useAssetClassBreakdown';
import usePerformanceBreakdown from 'api/hooks/home/usePerformanceBreakdown';
import Period from 'collections/enums';
import type { MarketSummary } from 'model/MarketSummary';

export function usePerformanceBreakdownData({
  client,
  portfolioName,
  url,
}: {
  client: string | undefined;
  portfolioName: string | undefined;
  url: string;
}) {
  const { data: performanceBreakdownReports } = usePerformanceBreakdown({
    client,
    portfolioName,
  });

  const data = useMemo(
    () =>
      performanceBreakdownReports?.map((report) => ({
        ...report,
        pathname: (() => {
          const baseUrl = `${url}/reports`;

          switch (report.name) {
            case 'Daily Estimate':
              return `${baseUrl}/daily-estimate`;
            case 'Month To Date':
              return `${baseUrl}/month-to-date`;
            case 'Year To Date':
              return `${baseUrl}/year-to-date`;
            case 'Since Inception':
            default:
              return `${baseUrl}/since-inception`;
          }
        })(),
      })),
    [performanceBreakdownReports, url],
  );

  return { data };
}

export function usePortfolioBalance(
  portfolio: string | undefined,
  lastUpdated: Date,
) {
  const { data: assetClassBreakdown } = useAssetClassBreakdown({
    period: Period.MONTHLY,
    portfolio,
    beginDate: undefined,
    endDate: undefined,
    lastUpdated,
  });

  return useMemo(() => {
    const portfolioBalance =
      assetClassBreakdown?.find((report) => report.name === 'Portfolio Balance')
        ?.endBalance ?? 0;

    const leverage =
      assetClassBreakdown?.find((report) => report.name === 'Leverage')
        ?.endBalance ?? 0;

    const portfolioEquity =
      assetClassBreakdown?.find((report) => report.name === 'Portfolio Equity')
        ?.endBalance ?? 0;

    return { leverage, portfolioBalance, portfolioEquity };
  }, [assetClassBreakdown]);
}

export function useScales(values: number[]) {
  let minValue = Math.min(...values);
  let maxValue = Math.max(...values);

  let scaleCorrectionFactor = 0;

  if (minValue < 0 && maxValue > 0) {
    scaleCorrectionFactor =
      Math.abs(minValue) < maxValue
        ? (maxValue - Math.abs(minValue)) / 2
        : (Math.abs(minValue) - maxValue) / 2;

    minValue -= Math.min(scaleCorrectionFactor, 5);
    maxValue += Math.min(scaleCorrectionFactor, 5);
  } else {
    scaleCorrectionFactor =
      Math.abs(Math.abs(maxValue) - Math.abs(minValue)) / 2;

    minValue > 0
      ? (maxValue += Math.min(scaleCorrectionFactor, 5))
      : (minValue -= Math.min(scaleCorrectionFactor, 5));
  }

  const valueScale = scaleLinear<number>({
    domain: [minValue, maxValue],
    nice: true,
    zero: true,
  });

  return { valueScale };
}

export function useValues(data: MarketSummary[], period: Period) {
  let keys = data.map((dt) => dt.instrumentType);
  keys = keys.filter((v, i) => !keys.includes(v, i + 1));

  const values = data.map(
    (dt) => (period === Period.YEARLY ? dt.ytd : dt.mtd) * 100,
  );

  return { values };
}

export function getBeginDateAndEndDate(
  rangeDate: [Date | undefined, Date | undefined],
  lastUpdated: Date,
) {
  const date = lastUpdated;

  const [beginDate, endDate] = rangeDate;

  if (beginDate && endDate) {
    return {
      beginDate,
      endDate,
    };
  }

  return {
    beginDate: new Date(date.getFullYear(), date.getMonth(), 1),
    endDate: lastUpdated,
  };
}

export function isDefaultPeriod(
  beginDate: Date | undefined,
  endDate: Date | undefined,
  period: Period,
) {
  const currentDate = new Date();

  if (beginDate && endDate) {
    if (period === Period.MONTHLY) {
      return (
        beginDate.getMonth() === currentDate.getMonth() &&
        endDate.getMonth() === currentDate.getMonth()
      );
    }
    if (period === Period.YEARLY) {
      return (
        beginDate.getFullYear() === currentDate.getFullYear() &&
        endDate.getFullYear() === currentDate.getFullYear()
      );
    }
  }

  return true;
}
