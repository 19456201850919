import { FC, memo, useEffect, useState } from 'react';

import { KeySortOfColumn, OrderOrientation } from 'utils/sort/types';

import {
  ColLeft,
  ColMoreInfo,
  ColouredAmount,
  Header,
  HeaderCell,
  HeaderTitle,
  LoaderContainer,
  MinusIcon,
  MoreInfo,
  MoreInfoRow,
  MoreInfoTitle,
  MoreInfoValue,
  NotFoundDataContainer,
  NotFoundDataImg,
  PlusIcon,
  Row,
  Spinner,
  Table,
  TableContainer,
  Title,
  VectorDownIcon,
  VectorUpIcon,
} from './styles';
import type { Props } from './types';

const DetailIncomeProjectionTable: FC<Props> = ({
  data,
  headerColumns,
  currentSorting,
  onChangeSorting,
  loading,
}) => {
  const [selectedRow, setSelectedRow] = useState<string | undefined>(undefined);
  const [sortColumn, setSortColumn] = useState<string>('Date');
  const [sortOrientation, setSortOrientation] = useState<string>(
    OrderOrientation.DESCENDENT,
  );

  useEffect(() => {
    if (headerColumns) {
      const currentSortingAux = currentSorting ?? 'default';

      const headerColumn = headerColumns.find(
        (col) =>
          col.keyOrderAsc === currentSortingAux ||
          col.keyOrderDesc === currentSortingAux,
      );

      if (headerColumn) {
        setSortColumn(headerColumn.titleColumn);
        setSortOrientation(
          headerColumn.keyOrderAsc === currentSortingAux
            ? OrderOrientation.ASCENDENT
            : OrderOrientation.DESCENDENT,
        );
      }
    }
  }, [headerColumns, currentSorting]);

  const handleSortByColumn = (sortByColumnSelected: KeySortOfColumn) => {
    if (onChangeSorting) {
      if (sortByColumnSelected.titleColumn === sortColumn) {
        onChangeSorting(
          sortOrientation === OrderOrientation.DESCENDENT
            ? sortByColumnSelected.keyOrderAsc
            : sortByColumnSelected.keyOrderDesc,
        );
      } else if (sortByColumnSelected.titleColumn !== sortColumn) {
        onChangeSorting(sortByColumnSelected.keyOrderDesc);
      }
    }
  };

  return (
    <TableContainer>
      <Table>
        <Header>
          {headerColumns.map((col) => (
            <HeaderCell key={col.titleColumn}>
              <HeaderTitle
                onClick={() =>
                  handleSortByColumn ? handleSortByColumn(col) : undefined
                }
              >
                <Title>{col.titleColumn}</Title>
                {sortColumn === col.titleColumn &&
                  (sortOrientation === OrderOrientation.DESCENDENT ? (
                    <VectorDownIcon />
                  ) : (
                    <VectorUpIcon />
                  ))}
              </HeaderTitle>
            </HeaderCell>
          ))}
        </Header>
        {!loading &&
          data.map((row) => (
            <div key={row.id}>
              <Row key={row.id}>
                <ColLeft>{row.name}</ColLeft>
                <ColLeft>{row.entity}</ColLeft>
                <ColLeft>{row.account}</ColLeft>
                <ColLeft>{row.category}</ColLeft>
                <ColouredAmount value={row.amount} withDecimals />
                <ColMoreInfo
                  onClick={() =>
                    setSelectedRow(selectedRow !== row.id ? row.id : undefined)
                  }
                >
                  {selectedRow === row.id ? <MinusIcon /> : <PlusIcon />}
                </ColMoreInfo>
              </Row>
              {selectedRow === row.id && (
                <MoreInfo>
                  <MoreInfoRow>
                    <MoreInfoTitle>Transaction Type: </MoreInfoTitle>
                    <MoreInfoValue>{row.transactionType}</MoreInfoValue>
                  </MoreInfoRow>
                  <MoreInfoRow>
                    <MoreInfoTitle>Asset Class: </MoreInfoTitle>
                    <MoreInfoValue>{row.assetClass}</MoreInfoValue>
                  </MoreInfoRow>
                  <MoreInfoRow>
                    <MoreInfoTitle>Income Type: </MoreInfoTitle>
                    <MoreInfoValue>{row.incomeType}</MoreInfoValue>
                  </MoreInfoRow>
                </MoreInfo>
              )}
            </div>
          ))}
        {!loading && data.length === 0 && (
          <NotFoundDataContainer>
            <NotFoundDataImg />
          </NotFoundDataContainer>
        )}
        {loading && (
          <LoaderContainer>
            <Spinner />
          </LoaderContainer>
        )}
      </Table>
    </TableContainer>
  );
};

export default memo(DetailIncomeProjectionTable);
