import styled from 'styled-components';

import hexToRgb from 'utils/hexToRgb';

import type { $BarProps } from './types';

export const Bar = styled.rect.attrs<$BarProps>(
  ({ $isEstimatedData, $color, height, width }) => ({
    fill: $isEstimatedData ? `rgba(${hexToRgb($color)}, 0.6)` : $color,
    stroke: 0,
    strokeWidth: 0,
    strokeDasharray: `${width} ${height}`,
  }),
)<$BarProps>``;
