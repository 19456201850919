import { useState } from 'react';
import { useRouteMatch } from 'react-router';

import useProfitLossChartData from 'api/hooks/charts/useProfitLossChartData';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import useMarketSummary from 'api/hooks/markets/useMarketSummary';
import Period from 'collections/enums';

import type { Data } from './AssetClassBreakdown/Controls/types';
import {
  getBeginDateAndEndDate,
  isDefaultPeriod,
  usePerformanceBreakdownData,
  usePortfolioBalance,
} from './logic';

export default function useConnect() {
  const [controls, setControls] = useState<{
    data: Data;
    period: Period;
  }>({
    data: 'activity',
    period: Period.MONTHLY,
  });
  const { selectedClient, selectedPortfolio } = useSelectedClient();

  const [rangeDate, setRangeDate] = useState<
    [Date | undefined, Date | undefined]
  >([undefined, undefined]);

  const { beginDate, endDate } = getBeginDateAndEndDate(
    rangeDate,
    new Date(selectedPortfolio?.lastUpdated || new Date()),
  );

  const { url } = useRouteMatch();

  const { leverage, portfolioBalance, portfolioEquity } = usePortfolioBalance(
    selectedPortfolio?.id,
    new Date(selectedPortfolio?.lastUpdated || new Date()),
  );
  const { data: performanceBreakdownReports } = usePerformanceBreakdownData({
    client: selectedClient?.id,
    portfolioName: selectedPortfolio?.name,
    url,
  });
  const { data: profitLossChartData } = useProfitLossChartData({
    client: selectedClient?.id,
    portfolioName: selectedPortfolio?.name,
  });

  const { data: marketSummaryData } = useMarketSummary({
    period: controls.period,
    portfolio: selectedPortfolio?.id,
    beginDate,
    endDate,
    lastUpdated: new Date(selectedPortfolio?.lastUpdated || new Date()),
  });

  return {
    leverage,
    performanceBreakdownReports,
    portfolioBalance,
    portfolioEquity,
    profitLossChartData,
    marketSummaryData,
    url,
    rangeDate,
    isDefaultPeriod: isDefaultPeriod(beginDate, endDate, controls.period),
    handle: {
      changeControls: setControls,
      changeRangeDate: setRangeDate,
    },
  };
}
