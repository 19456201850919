/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import DetailIncomeProjectionTable from 'components/DetailIncomeProjectionTable';
import type { DataChartType } from 'components/IncomeProjectionChart/types';
import SummaryIncomeProjectionTable from 'components/SummaryIncomeProjectionTable';
import { useNews } from 'containers/Services/NewsService';

import useConnect from './connect';
import ControlTabFilterYear from './ControlTabFilterYear';
import ControlTabProfitType from './ControlTabProfitTypes';
import ControlTabTaxesType from './ControlTabTaxesTypes';
import CustomYear from './CustomYear';
import { calculateIncomeTypeAmount, getIncomeProjectionYear } from './logic';
import {
  Container,
  ContainerControls,
  ContainerControlsFilter,
  ContainerControlsLeft,
  ContainerControlsTab,
  ContainerCustomYear,
  ContainerDateControls,
  ContainerTitle,
  Content,
  DataDisplayButton,
  FilterButton,
  IncomeProjectionChart,
  LoaderContainer,
  ResetLink,
  Spinner,
  Subtitle,
  TextAmount,
  Title,
  TotalAmount,
  Value,
} from './styles';
import FilterYear from './types';

const IncomeProjection: FC = () => {
  const [dataChartType, setDataChartType] = useState<DataChartType>('amount');
  const { replace } = useHistory();
  const newsServices = useNews();

  const {
    filterEntity,
    filterAccount,
    entitiesOptions,
    accountsOptions,
    incomeProjectionData,
    incomeProjectionDetail,
    dateOptions,
    selectedDate,
    headerColumns,
    currentSorting,
    incomeProfitType,
    incomeTaxesType,
    filterYear,
    customYear,
    handle,
    loading,
  } = useConnect();

  const chartsDataWithAmount = useMemo(
    () => calculateIncomeTypeAmount(incomeProjectionData),
    [incomeProjectionData],
  );

  const chartsDataWithPercentage = useMemo(() => {
    const dataWithAmount = calculateIncomeTypeAmount(incomeProjectionData);

    return dataWithAmount.map((item: any) => {
      const incomeTypeAmount = { ...item };
      Object.keys(incomeTypeAmount).forEach((key) => {
        if (key !== 'amount' && key !== 'date' && key !== 'id') {
          if (incomeTypeAmount[key] && item.amount) {
            incomeTypeAmount[key] = (incomeTypeAmount[key] ?? 0) / item.amount;
          }
        }
      });

      return incomeTypeAmount;
    });
  }, [incomeProjectionData]);

  useEffect(() => {
    if (newsServices?.notificationToNews) {
      replace('/news');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsServices?.notificationToNews]);

  const onChangeDataChartType = () => {
    if (dataChartType === 'amount') {
      setDataChartType('percentage');
    } else {
      setDataChartType('amount');
    }
  };

  return (
    <>
      {false && (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      )}
      <Container $loading={false}>
        <Content>
          <ContainerTitle>
            <Title>Income Projection</Title>
            <TotalAmount>
              <Value
                value={
                  incomeProjectionData.reduce(
                    (total, current) => total + current.amount,
                    0,
                  ) ?? 0
                }
              />
              <TextAmount>{`Total Income (For ${getIncomeProjectionYear(
                customYear?.getFullYear(),
                filterYear,
              )})`}</TextAmount>
            </TotalAmount>
          </ContainerTitle>
          <ContainerControlsLeft>
            <ContainerDateControls>
              <ControlTabFilterYear
                onChange={handle.onChangeFilterYear}
                selectedFilterYear={filterYear}
              />
              <ContainerCustomYear>
                <CustomYear
                  selectedCustomYear={customYear}
                  onChange={handle.onChangeCustomYear}
                  onClear={handle.onClearCustomYear}
                  onFocus={() =>
                    handle.onChangeFilterYear(FilterYear.CUSTOM_YEAR)
                  }
                />
              </ContainerCustomYear>
              <ResetLink onClick={handle.onResetFilterLink}>
                Reset All Filters
              </ResetLink>
            </ContainerDateControls>
          </ContainerControlsLeft>
          <ContainerControlsLeft>
            <ContainerControlsTab>
              <ControlTabProfitType
                onChange={handle.onChangeIncomeProfitType}
                selectedIncomeProfitType={incomeProfitType}
              />
            </ContainerControlsTab>
            <ContainerControlsTab>
              <ControlTabTaxesType
                onChange={handle.onChangeIncomeTaxesType}
                selectedIncomeTaxesType={incomeTaxesType}
              />
            </ContainerControlsTab>
          </ContainerControlsLeft>
          <IncomeProjectionChart
            data={
              dataChartType === 'amount'
                ? chartsDataWithAmount
                : chartsDataWithPercentage
            }
            loading={loading}
            dataChartType={dataChartType}
            onChangeDataChartType={onChangeDataChartType}
          />
          <ContainerControls>
            <Subtitle>Monthly Income Projection</Subtitle>
            <ContainerControlsFilter>
              <FilterButton
                onChangeMultiple={handle.onChangeFilteringEntity}
                options={
                  entitiesOptions.filter((opt) => opt.value !== 'default') ?? []
                }
                value={filterEntity?.join()}
                placeholder="Entity"
                multiple
                showLeft
                textSmall
              />
              <FilterButton
                onChangeMultiple={handle.onChangeFilteringAccount}
                options={
                  accountsOptions.filter((opt) => opt.value !== 'default') ?? []
                }
                value={filterAccount?.join()}
                placeholder="Account"
                multiple
                showLeft
                textSmall
              />
              <DataDisplayButton
                options={dateOptions}
                value={selectedDate}
                onChange={handle.onChangeSelectedDate}
                placeholder="Summary"
              />
            </ContainerControlsFilter>
          </ContainerControls>
          {incomeProjectionDetail ? (
            <DetailIncomeProjectionTable
              data={incomeProjectionDetail}
              headerColumns={headerColumns}
              currentSorting={currentSorting}
              onChangeSorting={handle.onChangeSorting}
              loading={loading}
            />
          ) : (
            <SummaryIncomeProjectionTable
              data={incomeProjectionData}
              loading={loading}
            />
          )}
        </Content>
      </Container>
    </>
  );
};

export default IncomeProjection;
