import styled, { css } from 'styled-components';

import { ReactComponent as DefaultMinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as DefaultPlusIcon } from 'assets/icons/plus.svg';
import DefaultSpinner from 'components/Spinner';
import from from 'styles/responsive';

export const Table = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colors.white};
  > :nth-child(even) {
    background: ${({ theme }) => theme.colors.dark1};
  }
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr) 1.5rem;
  column-gap: 1.5rem;
  padding: 1rem 1.5rem;
  color: ${({ theme }) => theme.colors.white75};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  text-align: right;
  > :first-child {
    text-align: left;
  }
`;

export const HeaderCell = styled.div`
  font-size: 0.875rem;
  line-height: 1.0625rem;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr) 1.5rem;
  column-gap: 1.5rem;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
  padding: 1rem 1.5rem;
`;

export const FirstCol = styled.div`
  text-align: left;
  padding: 0 0.25rem;
  font-size: 1rem;
  line-height: 1.0625rem;
`;

export const LastRow = styled(Row)`
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const LastFirstCol = styled(FirstCol)`
  font-size: 0.875rem;
`;

export const Col = styled.div`
  text-align: right;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;

  div {
    margin: 3rem;
  }
`;

export const ColMoreInfo = styled.div`
  cursor: pointer;
  text-align: center;
  height: 1.5rem;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;

  &:hover {
    background: ${({ theme }) => theme.colors.primary50};
  }
`;

export const MoreInfo = styled.div`
  padding: 1rem;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
`;

export const MoreInfoTable = styled.div<{
  $columns: number;
}>`
  display: grid;
  grid-auto-flow: column;
  border-radius: 0.5rem;
  overflow: hidden;
  margin: 0 10%;
  > :not(:last-child) {
    border-right: 0.5px solid ${({ theme }) => theme.colors.white10};
  }
  ${({ $columns }) =>
    $columns &&
    css`
      grid-template-columns: repeat(${$columns}, 1fr);
    `}
`;

export const Cell = styled.div`
  padding: 1rem 0.625rem;
  background-color: ${({ theme }) => theme.colors.primary10};
  text-align: center;
`;

export const CellAmount = styled.div`
  margin: 0 0 0.4063rem;
  font-size: 1rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.bold};

  ${from.laptop`
    font-size: 1.2rem;
    line-height: 1.1rem;
  `}
`;

export const CellTitle = styled.span`
  font-size: 0.75rem;
  line-height: 0.9rem;
  color: ${({ theme }) => theme.colors.white75};
  ${from.laptop`
    font-size: 0.9rem;
    line-height: 1rem;
  `}
`;

export const PlusIcon = styled(DefaultPlusIcon)``;

export const MinusIcon = styled(DefaultMinusIcon)``;

export const Spinner = styled(DefaultSpinner)``;
