import type { FC } from 'react';
import { memo } from 'react';

import { formatAmount } from 'utils/amounts';
import { formatMonth } from 'utils/dates';
import { formatPercentageWithDecimalsIfNecessary } from 'utils/percentages';

import { getBarKeyColor } from '../logic';
import { Key, KeyLabel, KeySquare, TooltipWithBounds, Value } from './styles';
import type { Props } from './types';

const Tooltip: FC<Props> = ({
  data: { date, value, key },
  keys,
  tooltipLeft,
  tooltipTop,
  dataChartType,
}) => (
  <TooltipWithBounds top={tooltipTop} left={tooltipLeft}>
    <Key>{formatMonth(date)}</Key>
    <Key>
      <KeySquare $color={getBarKeyColor(keys.indexOf(key)) ?? ''} />
      <KeyLabel>{key}</KeyLabel>
    </Key>
    {dataChartType === 'amount' ? (
      <Value>{formatAmount(+value)}</Value>
    ) : (
      <Value>{formatPercentageWithDecimalsIfNecessary(+value)}</Value>
    )}
  </TooltipWithBounds>
);

export default memo(Tooltip);
