/* eslint-disable @typescript-eslint/no-explicit-any */
import { localPoint } from '@visx/event';
import { scaleBand, scaleLinear } from '@visx/scale';
import { useTooltip as useVisxTooltip } from '@visx/tooltip';
import { useMemo } from 'react';
import type { MouseEvent, TouchEvent } from 'react';

import type { Theme } from 'styles/themes';
import unreachable from 'utils/unreachable';

import { BarKey, Datum, TooltipDatum } from './types';

export const getDate = (d: Datum) => d.date;
export function formatBarKey(key: BarKey) {
  switch (key) {
    case BarKey.ALTERNATIVES:
      return 'Alternatives';
    case BarKey.CASH:
      return 'Cash';
    case BarKey.COMMODITY:
      return 'Commodity';
    case BarKey.EQUITY_INDIVIDUAL:
      return 'Equity-Individual';
    case BarKey.EQUITY_MARKET:
      return 'Equity-Market';
    case BarKey.FIXED_INCOME:
      return 'Fixed Income';
    default:
    case BarKey.PRIVATE_INVESTMENTS:
      return 'Private Investments';
  }
}

export function getBarKeysOrdered(d: any) {
  const keys = Object.keys(d).slice(2);
  const values = Object.values(d).slice(2) as number[];

  return sort(keys, values);
}

function sort(arrayKeys: string[], arrayValues: number[]) {
  const tempArrayKeys = arrayKeys ?? [];
  const tempArrayValues = arrayValues ?? [];

  let ordinationFinished = true;

  tempArrayValues?.forEach((_valor, key) => {
    if (
      (tempArrayValues[key] ?? 0) < (tempArrayValues[key + 1] ?? 0) &&
      tempArrayValues.length - 1 !== key
    ) {
      // Swap the first position for the second to keys
      const firstKey = tempArrayKeys[key] ?? '';
      const secondKey = tempArrayKeys[key + 1] ?? '';
      tempArrayKeys[key] = secondKey;
      tempArrayKeys[key + 1] = firstKey;

      // Swap the first position for the second to values
      const firstValue = tempArrayValues[key] ?? 0;
      const secondValue = tempArrayValues[key + 1] ?? 0;
      tempArrayValues[key] = secondValue;
      tempArrayValues[key + 1] = firstValue;

      ordinationFinished = false;
    }
  });

  if (!ordinationFinished) {
    sort(tempArrayKeys, tempArrayValues);
  }

  return tempArrayKeys;
}

const colorList = [
  'chartLightBlue',
  'chartLightGreen',
  'chartLightYellow',
  'chartLightOrange',
  'chartLightPink',
  'chartLightPurple',
  'chartLightMint',
] as (keyof Theme['colors'])[];

export function getBarKeyColor(index: number) {
  return colorList[index] as keyof Theme['colors'];
}

function isBarKey(
  key: string | undefined,
  barKeys: readonly BarKey[],
): key is BarKey {
  return barKeys.includes(key as BarKey);
}

export function useHeight({
  isTablet,
  isLandscape,
}: {
  isTablet: boolean;
  isLandscape: boolean;
}): number {
  if (isTablet) {
    return window.innerHeight - 280;
  }

  return isLandscape ? 320 : window.innerHeight - 380;
}

export function useEdgeValues({
  barKeys,
  data,
}: {
  barKeys: readonly BarKey[];
  data: readonly Datum[];
}) {
  const start = useMemo(
    () =>
      data.reduce(
        (lowestTime, datum) => Math.min(lowestTime, datum.date),
        Infinity,
      ),
    [data],
  );

  const end = useMemo(
    () =>
      data.reduce((highestTime, datum) => Math.max(highestTime, datum.date), 0),
    [data],
  );

  const edges = useMemo(
    () =>
      data.map((datum) => {
        const values = [...barKeys].map((key) => datum[key]);

        const positiveValues = values.filter((value) => value >= 0);
        const negativeValues = values.filter((value) => value < 0);

        const startValue = negativeValues.reduce(
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          (acc, value) => acc + value,
          0,
        );

        const endValue = positiveValues.reduce((acc, value) => acc + value, 0);

        return { startValue, endValue: Math.max(100, endValue) };
      }),
    [barKeys, data],
  );

  const maxValue = useMemo(
    () => Math.max(...edges.map((edge) => edge.endValue)),
    [edges],
  );
  const minValue = useMemo(
    () => Math.min(...edges.map((edge) => edge.startValue)),
    [edges],
  );

  return {
    end,
    maxValue,
    minValue,
    start,
  };
}

export function useMargins({ isTablet }: { isTablet: boolean }) {
  // This builds on top of the padding of the Container element. Instead of
  // putting all on the padding, we put 1rem here so the chart line, which has
  // a thick stroke, doesn't get cut. We also put the rest on the Container
  // element so it is easier for other developers to change the styles.
  const topMargin = 16;

  // This can't simply be padding on the Container element because it wouldn't
  // be noticed by the resize observer.
  const rightMargin = 32;

  const bottomAxisHeight = 60;
  const bottomAxisMargin = 19;

  const leftAxisWidth = isTablet ? 70 : 40;
  const leftAxisMargin = 16;

  return {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisWidth,
    rightMargin,
    topMargin,
  };
}

export function useScales(props: {
  bottomAxisHeight: number;
  bottomAxisMargin: number;
  data: Datum[];
  end: number;
  height: number;
  leftAxisMargin: number;
  leftAxisWidth: number;
  maxValue: number;
  minValue: number;
  rightMargin: number;
  start: number;
  topMargin: number;
  width: number;
}) {
  // Destructured here rather than on the "props" parameter itself so that the
  // parameter hint in IntelliJ based IDEs isn't gigantic.
  const {
    bottomAxisHeight,
    bottomAxisMargin,
    data,
    height,
    leftAxisMargin,
    leftAxisWidth,
    maxValue,
    minValue,
    rightMargin,
    topMargin,
    width,
  } = props;

  const xScale = useMemo(
    () =>
      scaleBand({
        domain: data.map(getDate),
        padding: 0.1,
        range: [leftAxisWidth + leftAxisMargin, width - rightMargin],
        paddingOuter: 0,
      }),
    [leftAxisMargin, leftAxisWidth, rightMargin, width, data],
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [minValue, maxValue],
        range: [height - bottomAxisHeight - bottomAxisMargin, topMargin],
        nice: false,
      }),
    [bottomAxisHeight, bottomAxisMargin, height, minValue, maxValue, topMargin],
  );

  return { xScale, yScale };
}

export function useTooltip({
  barKeys,
  data,
}: {
  xScale: ReturnType<typeof useScales>['xScale'];
  yScale: ReturnType<typeof useScales>['yScale'];
  barKeys: readonly BarKey[];
  data: readonly Datum[];
  leftAxisMargin: number;
  leftAxisWidth: number;
  height: number;
  width: number;
}) {
  const { hideTooltip, showTooltip, tooltipData, tooltipLeft, tooltipTop } =
    useVisxTooltip<TooltipDatum>();

  const handleTooltipUpdated = useMemo(
    () => (event: MouseEvent<SVGRectElement> | TouchEvent<SVGRectElement>) => {
      if (event.target instanceof SVGRectElement) {
        // bar chart tooltip
        const { x: xBar, y: yBar } = localPoint(event) ?? { x: 0, y: 0 };

        const { datumId, key } = event.target.dataset;
        const datumBar = data.find((it) => it.id === datumId);

        if (!datumBar || !isBarKey(key, barKeys)) {
          return unreachable(undefined);
        }
        showTooltip({
          tooltipData: {
            date: datumBar.date,
            key,
            value: datumBar[key] / 100,
          },
          tooltipLeft: xBar,
          tooltipTop: yBar,
        });
      }

      return undefined;
    },
    [barKeys, data, showTooltip],
  );

  return {
    handleTooltipClosed: hideTooltip,
    handleTooltipUpdated,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  };
}
