import { scaleBand, scaleOrdinal } from '@visx/scale';

import Period from 'collections/enums';
import type { MarketSummary } from 'model/MarketSummary';
import dark from 'styles/themes/dark';

export function getChartColors() {
  return [
    dark.colors.primary,
    dark.colors.primary50,
    dark.colors.dataCarrot,
    dark.colors.dataBlue,
    dark.colors.dataMantis,
  ];
}

export function getWordMargin(word: string) {
  const wordLength = word.length;
  switch (wordLength) {
    case 1:
      return 19;
    case 2:
      return 26;
    case 3:
      return 29;
    case 4:
      return 35;
    case 5:
      return 38;
    case 6:
      return 46;
    case 7:
      return 53;
    case 8:
      return 59;
    case 9:
      return 64;
    case 10:
      return 68;
    case 11:
      return 72;
    case 12:
      return 78;
    case 13:
      return 82;
    case 14:
      return 87;
    case 15:
      return 96;
    default:
      return 102;
  }
}

export function useScales(keys: string[], data: MarketSummary[]) {
  const getName = (d: { [key: string]: string | number }) => d.name;

  const nameScale = scaleBand<string>({
    domain: data.map(getName) as string[],
    padding: 0.2,
  });

  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: getChartColors().slice(0, keys.length).reverse(),
  });

  return { nameScale, getName, colorScale };
}

export function useValues(data: MarketSummary[], period: Period) {
  let keys = data.map((dt) => dt.instrumentType);
  keys = keys.filter((v, i) => !keys.includes(v, i + 1));

  const values = data.map(
    (dt) => (period === Period.YEARLY ? dt.ytd : dt.mtd) * 100,
  );
  const valuesFormmated = data.map((dt) => {
    const newObj: { [key: string]: string | number } = {
      name: dt.name,
      shortName: dt.shortName,
    };
    keys.forEach((key) => {
      newObj[key] =
        dt.instrumentType === key
          ? (period === Period.YEARLY ? dt.ytd : dt.mtd) * 100
          : 0;
    });
    return newObj;
  });

  return { keys, values, valuesFormmated };
}
