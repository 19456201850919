import type { Dispatch, SetStateAction } from 'react';

export type Datum = Readonly<{
  id: string;
  date: number;
  alternatives: number;
  cash: number;
  commodity: number;
  equityIndividual: number;
  equityMarket: number;
  fixedIncome: number;
  privateInvestments: number;
}>;

export type Props = {
  className?: string;
  data: readonly Datum[];
  handleClickInstrumentType: (instrumentType: string | undefined) => void;
  isVisible: boolean;
  onChangeTooltip?: Dispatch<SetStateAction<boolean>>;
};

export type $AxisProps = {
  $isTablet: boolean;
};

export enum BarKey {
  ALTERNATIVES = 'alternatives',
  CASH = 'cash',
  COMMODITY = 'commodity',
  EQUITY_INDIVIDUAL = 'equityIndividual',
  EQUITY_MARKET = 'equityMarket',
  FIXED_INCOME = 'fixedIncome',
  PRIVATE_INVESTMENTS = 'privateInvestments',
}

export type TooltipDatum = Readonly<{
  date: number;
  key: BarKey;
  value: number;
}>;
