import { FC, useState } from 'react';

import Period from 'collections/enums';
import MarketOverviewTable from 'components/MarketOverviewTable';

import useConnect from './connect';
import { Container, Content, TableTabs } from './styles';

const tableTabs = [
  { label: 'Daily Change', value: Period.DAILY },
  { label: 'MTD', value: Period.MONTHLY },
  { label: 'YTD', value: Period.YEARLY },
];

const MarketsCategoryDetail: FC = () => {
  const { categoryName, data, handle, parentUrl } = useConnect();

  const [activeTableTab, setActiveTableTab] = useState<Period>(Period.DAILY);

  return (
    <Container parentUrl={parentUrl} title={categoryName} withSubtitle={false}>
      <Content>
        <TableTabs
          value={activeTableTab}
          tabs={tableTabs}
          onChange={setActiveTableTab}
          variant="bg-tabs"
        />
        <MarketOverviewTable
          data={data}
          onPin={handle.addToWatchlist}
          onUnpin={handle.removeFromWatchlist}
          selectedData={activeTableTab}
        />
      </Content>
    </Container>
  );
};

export default MarketsCategoryDetail;
