import type { TickFormatter } from '@visx/axis';
import { FC, memo, useMemo } from 'react';
import useResizeObserver from 'use-resize-observer';

import { formatAmountShort } from 'utils/amounts';
import { formatMonthShortYear } from 'utils/dates';

import BarChart from './BarChart';
import Legend from './Legend';
import {
  useEdgeValues,
  useHeight,
  useMargins,
  useScales,
  useTooltip,
} from './logic';
import {
  AxisBottom,
  AxisLeft,
  ChangeDataChartTypeLink,
  Container,
  GraphVector,
  Grid,
  LoaderContainer,
  Spinner,
  Title,
} from './styles';
import Tooltip from './Tooltip';
import type { Props } from './types';

const IncomeProjectionChart: FC<Props> = ({
  className,
  data: readonlyData,
  loading,
  dataChartType,
  onChangeDataChartType,
}) => {
  // visx wants mutable arrays even though it will never mutate them
  const data = useMemo(
    () => (Array.isArray(readonlyData) ? [...readonlyData] : []),
    [readonlyData],
  );

  const keySet = new Set<string>();
  data.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (key !== 'amount' && key !== 'date' && key !== 'id') {
        keySet.add(key);
      }
    });
  });

  const barKeys: string[] = Array.from(keySet);

  const { ref, width = 0 } = useResizeObserver();
  const height = useHeight();

  const {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisWidth,
    rightMargin,
    topMargin,
  } = useMargins();
  const { end, maxValue, minValue, start } = useEdgeValues({ barKeys, data });
  const { xScaleBars, yScale } = useScales({
    bottomAxisHeight,
    bottomAxisMargin,
    data,
    end,
    height,
    leftAxisMargin,
    leftAxisWidth,
    maxValue,
    minValue,
    rightMargin,
    start,
    topMargin,
    width,
    barKeys,
  });
  const {
    handleTooltipClosed,
    handleTooltipUpdated,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  } = useTooltip({
    xScale: xScaleBars,
    yScale,
    height: topMargin + bottomAxisHeight,
    width,
    leftAxisMargin,
    leftAxisWidth,
    barKeys,
    data,
  });

  return (
    <Container ref={ref} className={className}>
      <ChangeDataChartTypeLink onClick={onChangeDataChartType}>
        {`Change to ${dataChartType === 'amount' ? 'Percentage' : 'Amount'}`}
      </ChangeDataChartTypeLink>
      {!loading && (
        <GraphVector width="100%" viewBox={`0 0 ${width} ${height}`}>
          <Grid
            scale={yScale}
            width={width - leftAxisWidth - leftAxisMargin - rightMargin}
            left={leftAxisWidth + leftAxisMargin}
          />
          <AxisLeft
            left={leftAxisWidth}
            numTicks={7}
            scale={yScale}
            tickFormat={formatAmountShort as TickFormatter<unknown>}
          />
          <AxisBottom
            left={0}
            tickValues={data.map((datum) => datum.date)}
            scale={xScaleBars}
            top={height - bottomAxisHeight}
            tickFormat={formatMonthShortYear}
          />
          <Title
            $x={25}
            $y={height / 2}
            dominantBaseline="central"
            textAnchor="middle"
          >
            {`Monthly Income Projection (${
              dataChartType === 'amount' ? 'US$' : '%'
            })`}
          </Title>
          <BarChart
            data={data}
            handleTooltipClosed={handleTooltipClosed}
            handleTooltipUpdated={handleTooltipUpdated}
            keys={barKeys}
            xScale={xScaleBars}
            yScale={yScale}
          />
        </GraphVector>
      )}
      {!loading && <Legend keys={barKeys} />}
      {typeof tooltipData !== 'undefined' &&
        typeof tooltipLeft === 'number' &&
        typeof tooltipTop === 'number' && (
          <Tooltip
            data={tooltipData}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
            keys={barKeys}
            dataChartType={dataChartType}
          />
        )}
      {loading && (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      )}
    </Container>
  );
};

export default memo(IncomeProjectionChart);
