import type { GetEstimatedIncomeResponse } from 'api/queries/incomeProjection/getEstimatedIncome/types';
import type { WithoutNulls } from 'utils/withoutNulls';

import { normalizeEstimatedIncomeAsset } from './EstimatedIncomeAsset';

type Input = NonNullable<
  NonNullable<
    NonNullable<GetEstimatedIncomeResponse['data']>
  >['estimatedIncomeDetails']
>[number];

export const normalizeEstimatedIncomeMonth = (input: WithoutNulls<Input[]>) => {
  const id = new Date(input[0]?.eomonthDate ?? '').getTime().toString();

  const assets = input.map((asset) => normalizeEstimatedIncomeAsset(asset, id));

  return {
    id,
    assets,
    eomonthDate: new Date(input[0]?.eomonthDate ?? 0).getTime(),
    amount: assets.reduce(
      (sum, asset) => Number(sum) + Number(asset.amount ?? 0),
      0,
    ),
  } as const;
};

export type EstimatedIncomeMonth = ReturnType<
  typeof normalizeEstimatedIncomeMonth
>;
