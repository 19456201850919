/* eslint-disable no-param-reassign */
import { FC, memo, useMemo, useState } from 'react';

import Amount from 'components/Amount';
import { formatMonthYear } from 'utils/dates';

import {
  Cell,
  CellAmount,
  CellTitle,
  Col,
  ColMoreInfo,
  FirstCol,
  Header,
  HeaderCell,
  LastFirstCol,
  LastRow,
  LoaderContainer,
  MinusIcon,
  MoreInfo,
  MoreInfoTable,
  PlusIcon,
  Row,
  Spinner,
  Table,
} from './styles';
import type { Props } from './types';

const SummaryIncomeProjectionTable: FC<Props> = ({ data, loading }) => {
  const [selectedRow, setSelectedRow] = useState<string | undefined>(undefined);
  const rows = useMemo(
    () =>
      data.map((item) => {
        const incomeTypeAmount = item.assets.reduce((acc, asset) => {
          if (!acc[asset.incomeType]) {
            acc[asset.incomeType] = 0;
          }

          acc[asset.incomeType] = (acc[asset.incomeType] || 0) + asset.amount;

          return acc;
        }, {} as Record<string, number>);

        return {
          id: item.id,
          name: formatMonthYear(item.eomonthDate),
          value: item.amount,
          incomeTypeAmount,
        };
      }),
    [data],
  );

  const total = useMemo(
    () => data.reduce((acc, item) => item.amount + acc, 0),
    [data],
  );

  const incomeTypeAmountTotals = useMemo(
    () =>
      rows.reduce((acc, row) => {
        Object.entries(row.incomeTypeAmount).forEach(([key, value]) => {
          if (!acc[key]) {
            acc[key] = 0;
          }
          acc[key] += value;
        });
        return acc;
      }, {} as Record<string, number>),
    [rows],
  );

  const headers = ['Month', 'Monthly Estimate'];

  return (
    <Table>
      <Header>
        {headers.map((header) => (
          <HeaderCell key={header}>{header}</HeaderCell>
        ))}
      </Header>
      {!loading &&
        rows.map((row) => (
          <div key={row.id}>
            <Row key={row.id}>
              <FirstCol>{row.name}</FirstCol>
              <Col>
                <Amount value={row.value} />
              </Col>
              {row.value > 0 && (
                <ColMoreInfo
                  onClick={() =>
                    setSelectedRow(selectedRow !== row.id ? row.id : undefined)
                  }
                >
                  {selectedRow === row.id ? <MinusIcon /> : <PlusIcon />}
                </ColMoreInfo>
              )}
            </Row>
            {selectedRow === row.id && (
              <MoreInfo>
                <MoreInfoTable
                  $columns={Object.entries(row.incomeTypeAmount).length}
                >
                  {Object.entries(row.incomeTypeAmount).map(
                    ([key, value], index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Cell key={`${index}_${row.id}`}>
                        <CellAmount>
                          <Amount value={value} />
                        </CellAmount>
                        <CellTitle>{key}</CellTitle>
                      </Cell>
                    ),
                  )}
                </MoreInfoTable>
              </MoreInfo>
            )}
          </div>
        ))}
      {!loading && (
        <div>
          <LastRow key="last-row">
            <LastFirstCol>Total</LastFirstCol>
            <Col>
              <Amount value={total} />
            </Col>
            {total > 0 && (
              <ColMoreInfo
                onClick={() =>
                  setSelectedRow(
                    selectedRow !== 'last-row' ? 'last-row' : undefined,
                  )
                }
              >
                {selectedRow === 'last-row' ? <MinusIcon /> : <PlusIcon />}
              </ColMoreInfo>
            )}
          </LastRow>
          {selectedRow === 'last-row' && (
            <MoreInfo>
              <MoreInfoTable
                $columns={Object.entries(incomeTypeAmountTotals).length}
              >
                {Object.entries(incomeTypeAmountTotals).map(
                  ([key, value], index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Cell key={`${index}_last-row`}>
                      <CellAmount>
                        <Amount value={value} />
                      </CellAmount>
                      <CellTitle>{key}</CellTitle>
                    </Cell>
                  ),
                )}
              </MoreInfoTable>
            </MoreInfo>
          )}
        </div>
      )}
      {loading && (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      )}
    </Table>
  );
};

export default memo(SummaryIncomeProjectionTable);
