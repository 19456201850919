import { IonRouterLink } from '@ionic/react';
import styled, { css } from 'styled-components';

import { ReactComponent as DefaultAllocationsIcon } from 'assets/icons/allocations.svg';
import { ReactComponent as DefaultAnalysisIcon } from 'assets/icons/analysis.svg';
import { ReactComponent as DefaultHomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as DefaultIncomeProjectionIcon } from 'assets/icons/income-projection.svg';
import { ReactComponent as DefaultMarketIcon } from 'assets/icons/market.svg';
import { ReactComponent as DefaultNewsIcon } from 'assets/icons/news.svg';
import { ReactComponent as DefaultProfileIcon } from 'assets/icons/profile.svg';
import { ReactComponent as DefaultTransactionsIcon } from 'assets/icons/transactions.svg';
import DefaultAvatarButton from 'components/AvatarButton';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.dark1};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Heading = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.white10};
  padding: 3.875rem 1.25rem 2rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3.125rem 1.25rem 3.75rem;
  justify-content: space-between;
  flex: 1;
`;

export const AvatarButton = styled(DefaultAvatarButton)`
  margin-top: 2rem;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const Name = styled.span`
  font-weight: ${({ theme }) => theme.weights.medium};
`;

export const LastUpdated = styled.span`
  color: ${({ theme }) => theme.colors.white75};
  font-size: 0.875rem;
`;

export const Navigation = styled.nav``;

export const LinkContainer = styled.div`
  :not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const Link = styled(IonRouterLink)<{ $active: boolean }>`
  color: ${({ theme }) => theme.colors.white50};

  ${({ $active }) =>
    $active &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `};
`;

export const LinkContent = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;

export const Label = styled.span`
  margin-left: 1.15rem;
  line-height: 1;
`;

export const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  left: 1rem;
  top: -0.125rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.statusDecrease};
  z-index: 1;
`;

export const HomeIcon = styled(DefaultHomeIcon)``;

export const AllocationsIcon = styled(DefaultAllocationsIcon)``;

export const AnalysisIcon = styled(DefaultAnalysisIcon)``;

export const MarketIcon = styled(DefaultMarketIcon)``;

export const TransactionsIcon = styled(DefaultTransactionsIcon)``;

export const IncomeProjectionIcon = styled(DefaultIncomeProjectionIcon)``;

export const NewsIcon = styled(DefaultNewsIcon)``;

export const ProfileIcon = styled(DefaultProfileIcon)``;

export const ProfileLinkContainer = styled(LinkContainer)``;
