/* eslint-disable @typescript-eslint/no-explicit-any */
import { localPoint } from '@visx/event';
import { scaleBand, scaleLinear } from '@visx/scale';
import { useTooltip as useVisxTooltip } from '@visx/tooltip';
import { useMemo } from 'react';
import type { MouseEvent, TouchEvent } from 'react';

import unreachable from 'utils/unreachable';

import type { TooltipDatum } from './types';

function isBarKey(key: string | undefined, barKeys: readonly string[]) {
  return barKeys.includes(key as string);
}

export const getDate = (d: any) => d.date;

const colorList = ['#3E77B9', '#267230', '#F3AE1F', '#DB4D33', '#B11F65'];

export function getBarKeyColor(index: number) {
  return colorList[index];
}

export function useHeight(): number {
  return 424;
}

export function useEdgeValues({
  barKeys,
  data,
}: {
  barKeys: readonly string[];
  data: readonly any[];
}) {
  const start = useMemo(
    () =>
      data.reduce(
        (lowestTime, datum) => Math.min(lowestTime, datum.date),
        Infinity,
      ),
    [data],
  );

  const end = useMemo(
    () =>
      data.reduce((highestTime, datum) => Math.max(highestTime, datum.date), 0),
    [data],
  );

  const edges = useMemo(
    () =>
      data.map((datum) => {
        const values = [...barKeys].map((key) => datum[key]);

        const positiveValues = values.filter((value) => value >= 0);
        const negativeValues = values.filter((value) => value < 0);

        const startValue = negativeValues.reduce(
          (acc, value) => (acc as number) + (value as number),
          0,
        );

        const endValue = positiveValues.reduce(
          (acc, value) => (acc as number) + (value as number),
          0,
        );

        return { startValue, endValue };
      }),
    [barKeys, data],
  );

  const maxValue = useMemo(
    () => Math.max(...edges.map((edge) => edge.endValue)),
    [edges],
  );
  const minValue = useMemo(
    () => Math.min(...edges.map((edge) => edge.startValue)),
    [edges],
  );

  return {
    end,
    maxValue,
    minValue,
    start,
  };
}

export function useMargins() {
  // This builds on top of the padding of the Container element. Instead of
  // putting all on the padding, we put 1rem here so the chart line, which has
  // a thick stroke, doesn't get cut. We also put the rest on the Container
  // element so it is easier for other developers to change the styles.
  const topMargin = 32;

  // This can't simply be padding on the Container element because it wouldn't
  // be noticed by the resize observer.
  const rightMargin = 32;

  const bottomAxisHeight = 36;
  const bottomAxisMargin = 16;

  const leftAxisWidth = 84;
  const leftAxisMargin = 16;

  return {
    bottomAxisHeight,
    bottomAxisMargin,
    leftAxisMargin,
    leftAxisWidth,
    rightMargin,
    topMargin,
  };
}

export function useScales(props: {
  bottomAxisHeight: number;
  bottomAxisMargin: number;
  data: any[];
  end: number;
  height: number;
  leftAxisMargin: number;
  leftAxisWidth: number;
  maxValue: number;
  minValue: number;
  rightMargin: number;
  start: number;
  topMargin: number;
  width: number;
  barKeys: string[];
}) {
  // Destructured here rather than on the "props" parameter itself so that the
  // parameter hint in IntelliJ based IDEs isn't gigantic.
  const {
    bottomAxisHeight,
    bottomAxisMargin,
    data,
    height,
    leftAxisMargin,
    leftAxisWidth,
    maxValue,
    minValue,
    rightMargin,
    topMargin,
    width,
  } = props;

  const xScaleBars = useMemo(
    () =>
      scaleBand({
        domain: data.map(getDate),
        padding: 0.2,
        range: [leftAxisWidth + leftAxisMargin, width - rightMargin],
        paddingOuter: 0,
      }),
    [leftAxisMargin, leftAxisWidth, rightMargin, width, data],
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [minValue, maxValue],
        range: [height - bottomAxisHeight - bottomAxisMargin, topMargin],
      }),
    [bottomAxisHeight, bottomAxisMargin, height, minValue, maxValue, topMargin],
  );

  return { xScaleBars, yScale };
}

export function useTooltip({
  barKeys,
  data,
}: {
  xScale: ReturnType<typeof useScales>['xScaleBars'];
  yScale: ReturnType<typeof useScales>['yScale'];
  barKeys: readonly string[];
  data: readonly any[];
  leftAxisMargin: number;
  leftAxisWidth: number;
  height: number;
  width: number;
}) {
  const { hideTooltip, showTooltip, tooltipData, tooltipLeft, tooltipTop } =
    useVisxTooltip<TooltipDatum>();

  const handleTooltipUpdated = useMemo(
    () => (event: MouseEvent<SVGRectElement> | TouchEvent<SVGRectElement>) => {
      if (event.target instanceof SVGRectElement) {
        const { x: xBar, y: yBar } = localPoint(event) ?? { x: 0, y: 0 };
        const { datumId, key } = event.target.dataset;
        const datumBar = data.find((it) => it.id === datumId);

        if (!datumBar || !isBarKey(key, barKeys)) {
          return unreachable(undefined);
        }

        showTooltip({
          tooltipData: {
            date: datumBar.date,
            key: key as string,
            value: datumBar[key as string],
          },
          tooltipLeft: xBar,
          tooltipTop: yBar,
        });
      }

      return undefined;
    },
    [barKeys, data, showTooltip],
  );

  return {
    handleTooltipClosed: hideTooltip,
    handleTooltipUpdated,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  };
}
