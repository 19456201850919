/* eslint-disable no-loop-func */
/* eslint-disable @typescript-eslint/no-loop-func */
import { Mutex } from 'async-mutex';
import { isPast } from 'date-fns';
import { subSeconds } from 'date-fns/fp';
import { StatusCodes } from 'http-status-codes';
import { decode } from 'jsonwebtoken';
import { useCallback } from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import type { Overwrite } from 'utility-types';

import type { paths } from 'api/generated';
import { useMaintenanceMode } from 'containers/Services/MaintenanceModeService';
import type { MaintenanceModeContextModel } from 'containers/Services/MaintenanceModeService/types';
import { useRefreshToken } from 'containers/Services/RefreshTokenService';
import type { RefreshTokenContextModel } from 'containers/Services/RefreshTokenService/types';
import type { Tokens } from 'model/Tokens';
import env from 'utils/env';
import runExclusiveDiscarding from 'utils/mutex';
import usePlatform from 'utils/usePlatform/usePlatform';
import wait from 'utils/wait';

import useDeviceInfo from './hooks/auth/useDeviceInfo';
import type { SaveDeviceInfoVariables } from './mutations/auth/deviceInfo/types';
import {
  ACCESS_TOKEN_QUERY_KEY,
  AccessTokenQuery,
} from './queries/auth/accessToken';

const RETRIES_TO_REFRESH_TOKEN = 8;

const decodeJwt = decode as (jwt: string) => { exp: number } | null;

type ApiRequest<T extends unknown> = Overwrite<
  RequestInit,
  {
    body?: T;
  }
> & {
  urlParams?: Record<string, string>;
};

type ApiResponse<T extends unknown> = Overwrite<
  Response,
  { json(): Promise<T> }
>;

type RefreshTokenRequest =
  paths['/api/Authentication/refreshToken2']['post']['requestBody']['content']['application/json'];
type RefreshTokenResponse =
  paths['/api/Authentication/refreshToken2']['post']['responses'][200]['content']['application/json'];

function expireSession(queryClient: QueryClient) {
  queryClient.setQueryData<AccessTokenQuery>(ACCESS_TOKEN_QUERY_KEY, {
    accessToken: undefined,
    email: undefined,
    passwordResetData: undefined,
    refreshToken: undefined,
    termsAgreed: false,
  });
}

/*
 * We invalidate access tokens 30 seconds before they actually expire, in
 * order to avoid a possible race condition where the network transit is so
 * slow that the token is valid at the moment the request is sent but is
 * invalid the moment it reaches the back-end.
 */
const adjustTokenExpiration = subSeconds(30);

async function getFreshAccessToken(
  queryClient: QueryClient,
  refreshTokenContext: RefreshTokenContextModel | undefined,
  deviceInfo: (variables: SaveDeviceInfoVariables) => Promise<void>,
  secureStorage: boolean,
  forceRefreshToken = false,
): Promise<string | undefined> {
  const cache = queryClient.getQueryCache();
  const tokens: Tokens | undefined = cache.find<Tokens>(ACCESS_TOKEN_QUERY_KEY)
    ?.state.data;

  if (!tokens?.accessToken || !tokens.refreshToken) {
    return undefined;
  }

  const exp = (decodeJwt(tokens?.accessToken)?.exp ?? 0) * 1000;
  const adjustedExp = adjustTokenExpiration(exp).getTime();

  if (!isPast(adjustedExp) && !forceRefreshToken) {
    return tokens.accessToken;
  }

  const requestBody: RefreshTokenRequest = {
    jwtToken: tokens.accessToken,
    refreshToken: tokens.refreshToken,
    secureStorage,
  };

  let retries = 0;
  let result: RefreshTokenResponse | undefined;
  let invalidToken = false;

  while (retries < RETRIES_TO_REFRESH_TOKEN) {
    // eslint-disable-next-line no-await-in-loop
    result = await fetch(
      `${env('REACT_APP_API_ENDPOINT')}/api/Authentication/refreshToken2`,
      {
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
      },
    )
      .then(async (res) => {
        if (res.ok) {
          const refreshTokenResult = (await res.json()) as RefreshTokenResponse;
          if (refreshTokenResult.isSuccess) {
            refreshTokenContext?.setRefreshingToken(false);
            refreshTokenContext?.setCouldResfreshToken(true);
            return refreshTokenResult;
          }

          refreshTokenContext?.setLoadingRequestToken(false);
          refreshTokenContext?.setRefreshingToken(true);
        } else if (res.status >= 500) {
          refreshTokenContext?.setLoadingRequestToken(false);
          refreshTokenContext?.setRefreshingToken(true);
        } else if (res.status === 401) {
          invalidToken = true;
          refreshTokenContext?.setLoadingRequestToken(false);
          refreshTokenContext?.setRefreshingToken(true);
        }

        return undefined;
      })
      .catch(() => undefined);

    if (result || invalidToken) {
      break;
    }

    retries += 1;

    // eslint-disable-next-line no-await-in-loop
    await wait(retries * retries * 100);
  }

  if (!result || invalidToken) {
    refreshTokenContext?.setLoadingRequestToken(false);
    refreshTokenContext?.setCouldResfreshToken(false);

    return undefined;
  }

  if (result && !result.data?.hasDeviceInfo) {
    await deviceInfo({
      refreshToken: result.data?.refreshToken,
    });
  }

  queryClient.setQueryData<AccessTokenQuery>(
    ACCESS_TOKEN_QUERY_KEY,
    (query) => ({
      accessToken: result?.data?.token,
      email: query?.email,
      passwordResetData: query?.passwordResetData,
      refreshToken: result?.data?.refreshToken,
      termsAgreed: !!query?.termsAgreed,
    }),
  );

  return result?.data?.token;
}

class ServerError extends Error {
  readonly response: Response;

  constructor(response: Response) {
    super('Server Error');
    this.response = response;
  }
}

const refreshTokenMutex = new Mutex();

async function request<RequestT extends unknown, ResponseT extends unknown>(
  queryClient: QueryClient,
  refreshTokenContext: RefreshTokenContextModel | undefined,
  maintenanceModeContext: MaintenanceModeContextModel | undefined,
  deviceInfo: (variables: SaveDeviceInfoVariables) => Promise<void>,
  secureStorage: boolean,
  path: keyof paths,
  { body, ...requestParams }: ApiRequest<RequestT> = {},
  attempt = 0,
): Promise<ApiResponse<ResponseT>> {
  /*
   * Run in a mutex so that only one access token is requested at once.
   *
   * This is not just an optimization to not request more tokens than
   * required, this is necessary because the back-end rotates tokens on each
   * refresh (i.e. after a refresh, the old refresh token is revoked),
   * therefore if we made two refresh requests at the same time, the second one
   * would fail.
   */

  const accessToken = await refreshTokenMutex.runExclusive(() =>
    getFreshAccessToken(
      queryClient,
      refreshTokenContext,
      deviceInfo,
      secureStorage,
    ),
  );

  const baseUrl = `${env('REACT_APP_API_ENDPOINT')}${path}`;
  const url = Object.entries(requestParams.urlParams ?? {}).reduce(
    (currentUrl, [key, value]) => currentUrl.replace(`{${key}}`, value),
    baseUrl,
  );

  const response = await fetch(url, {
    body: body ? JSON.stringify(body) : undefined,
    headers: {
      'Content-Type': 'application/json',
      ...(accessToken && {
        Authorization: `Bearer ${accessToken}`,
      }),
      ...requestParams.headers,
    },
    ...requestParams,
  });

  if (accessToken && response.status === StatusCodes.UNAUTHORIZED) {
    /*
     * If the back-end sends "Unauthorized" maybe the access token was revoked
     * before it expired. Try again by forcing a refresh.
     */
    if (attempt === 0) {
      // Only refresh the token once. Because we're passing
      // forceRefreshToken = true, we have to discard subsequent runs,
      // otherwise a concurrent request will unnecessarily refresh the token
      // yet again after the current run is finished.
      await runExclusiveDiscarding(refreshTokenMutex, () =>
        getFreshAccessToken(queryClient, refreshTokenContext, deviceInfo, true),
      );

      // Wait a bit for the react-query cache to be updated so that when the
      // next attempt calls getFreshAccessToken, the new token is available.
      await wait(100);

      // Try the request again. Because this is "attempt + 1", if the request
      // fails again with 401 we won't try to refresh the token again.
      return request(
        queryClient,
        refreshTokenContext,
        maintenanceModeContext,
        deviceInfo,
        secureStorage,
        path,
        { body, ...requestParams },
        attempt + 1,
      );
    }

    /*
     * If we reached this point, the following happened:
     *
     * - We tried once the request with the old access token and got 401
     * - We forced a refresh on the access token and the refresh succeeded
     * - We tried again the request with the new access token and once again
     *   got 401 (???)
     *
     * At this point we can't assume to know the cause of the 401 and need to
     * expire the session so the user starts over.
     */
    expireSession(queryClient);
    throw new Error('Session expired');
  }

  if (response.status >= 500) {
    throw new ServerError(response);
  }

  refreshTokenContext?.setLoadingRequestToken(false);

  return response;
}

export default function useRequest<
  RequestT extends unknown,
  ResponseT extends unknown,
>() {
  const queryClient = useQueryClient();
  const refreshTokenContext = useRefreshToken();
  const maintenanceModeContext = useMaintenanceMode();
  const { deviceInfo } = useDeviceInfo();
  const { isWeb } = usePlatform();

  return useCallback(
    (
      path: keyof paths,
      options: ApiRequest<RequestT> = {},
    ): Promise<ApiResponse<ResponseT>> =>
      request<RequestT, ResponseT>(
        queryClient,
        refreshTokenContext,
        maintenanceModeContext,
        deviceInfo,
        !isWeb,
        path,
        options,
      ),
    [
      deviceInfo,
      isWeb,
      queryClient,
      refreshTokenContext,
      maintenanceModeContext,
    ],
  );
}
