import useGetEstimatedIncomeQuery, {
  GET_ESTIMATED_INCOME_QUERY_KEY,
} from 'api/queries/incomeProjection/getEstimatedIncome';
import { useQuery } from 'api/storage';

export default function useEstimatedIncome(
  portfolio: string | undefined,
  year: number,
) {
  const { data } = useQuery(
    GET_ESTIMATED_INCOME_QUERY_KEY(portfolio ?? '', year),
    {
      enabled: !!portfolio,
      queryFn: useGetEstimatedIncomeQuery(),
    },
  );

  return {
    data: data ?? undefined,
  };
}
