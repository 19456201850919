import { FC, memo } from 'react';

import Amount from 'components/Amount';
import BaseListButton from 'components/BaseListButton';
import { formatNumberDecimals } from 'utils/amounts';
import {
  formatPercentage,
  formatPercentageAsBondCoupon,
} from 'utils/percentages';
import { OrderOrientation } from 'utils/sort/types';

import {
  Col,
  Content,
  FirstCol,
  Header,
  HeaderCell,
  HeaderContainer,
  HeaderTitle,
  Row,
  Table,
  VectorDownIcon,
  VectorUpIcon,
} from '../styles';
import type { Props } from './types';

const DesktopVersion: FC<Props> = ({
  data,
  enabledTaxEquivalent,
  handleSortByColumn,
  sortApplied,
  headerColumns,
}) => {
  const handlerClick = (id: string) => {
    sessionStorage.setItem('itemPosition', id);
  };

  return (
    <Table>
      <HeaderContainer>
        <Header>
          {headerColumns.map((col) => (
            <HeaderCell key={col.titleColumn}>
              <HeaderTitle
                onClick={() =>
                  handleSortByColumn ? handleSortByColumn(col) : undefined
                }
              >
                {col.titleColumn}
                {sortApplied?.titleColumn === col.titleColumn &&
                  (sortApplied?.orderOrientation ===
                  OrderOrientation.DESCENDENT ? (
                    <VectorDownIcon />
                  ) : (
                    <VectorUpIcon />
                  ))}
              </HeaderTitle>
            </HeaderCell>
          ))}
        </Header>
      </HeaderContainer>
      <Content>
        {data.map((row) => (
          <BaseListButton
            key={`${row.id}-${row.value}`}
            pathname={row.pathname}
            onClick={() => handlerClick(row.id)}
          >
            <Row id={row.id}>
              <FirstCol>
                {row.name}
                <span>
                  {formatPercentageAsBondCoupon(row.coupon)} {row.maturity}
                </span>
              </FirstCol>
              <Col>
                <Amount value={row.value} />
                <span>{formatPercentage(row.allocation)}</span>
              </Col>
              <Col>
                {formatPercentage(enabledTaxEquivalent ? row.tey : row.ytw)}
              </Col>
              <Col>{formatNumberDecimals(row.ttc)}</Col>
              <Col>{formatNumberDecimals(row.duration)}</Col>
              <Col>{row.rating}</Col>
            </Row>
          </BaseListButton>
        ))}
      </Content>
    </Table>
  );
};
export default memo(DesktopVersion);
