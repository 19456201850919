import { Capacitor } from '@capacitor/core';
import { Formik } from 'formik';
import { FC, useEffect, useState } from 'react';

import Allocation from 'components/Allocation';
import SortInfo from 'components/SortInfo';
import SortItemsGroup from 'components/SortItemsGroup';
import SortModal from 'components/SortModal';
import { useAssetContext } from 'context/Asset';
import from from 'styles/responsive';
import { formatDate } from 'utils/dates';
import exportToExcel from 'utils/exportToExcel';
import searchWithoutSort from 'utils/searchWithoutSort';
import { KeySortOfColumn, OrderOrientation } from 'utils/sort/types';
import useMedia from 'utils/useMedia';
import usePlatform from 'utils/usePlatform/usePlatform';

import useConnect from './connect';
import {
  ActionZone,
  CashContainer,
  CashList,
  Container,
  Content,
  ExportToExcelButton,
  Form,
  Grid,
  Header,
  ModalTitle,
  Navigation,
  Stats,
  TableButton,
  TableContainer,
  Title,
  Toolbar,
} from './styles';

const CategoryDetailCash: FC = () => {
  const { asset, setAsset } = useAssetContext();
  const [sortColumn, setSortColumn] = useState<string>('Total ($)');
  const [sortOrientation, setSortOrientation] = useState<string>(
    OrderOrientation.DESCENDENT,
  );
  const {
    allocation,
    allocationChartData,
    assets,
    backUrl,
    balance,
    breadcrumbs,
    currentSorting,
    handle,
    initialValues,
    summary,
    modalOpen,
    navigationLinks,
    pageTitle,
    selectedUrl,
    sortOptions,
    headerColumns,
    url,
    search,
  } = useConnect();

  const isMobile = !useMedia(from.tablet.query);
  const { isWeb } = usePlatform();

  useEffect(() => {
    const currentSortingAux = currentSorting ?? 'default';

    const headerColumn = headerColumns.find(
      (col) =>
        col.keyOrderAsc === currentSortingAux ||
        col.keyOrderDesc === currentSortingAux,
    );

    if (headerColumn) {
      setSortColumn(headerColumn.titleColumn);
      setSortOrientation(
        headerColumn.keyOrderAsc === currentSortingAux
          ? OrderOrientation.ASCENDENT
          : OrderOrientation.DESCENDENT,
      );
    }
  }, [headerColumns, currentSorting]);

  useEffect(() => {
    if (asset && assets && Capacitor.getPlatform() !== 'web') {
      setTimeout(() => {
        document.getElementById(asset)?.scrollIntoView({ block: 'center' });
        setAsset('');
      }, 0);
    }
  }, [asset, setAsset, assets]);

  useEffect(() => {
    if (assets.length) {
      const itemPosition = sessionStorage.getItem('itemPosition');
      if (itemPosition) {
        setTimeout(() => {
          document
            .getElementById(itemPosition)
            ?.scrollIntoView({ block: 'center' });
          sessionStorage.removeItem('itemPosition');
        }, 0);
      }
    }
  }, [assets]);

  const handleSortByColumn = (sortByColumnSelected: KeySortOfColumn) => {
    if (sortByColumnSelected.titleColumn === sortColumn) {
      handle.changeSorting(
        sortOrientation === OrderOrientation.DESCENDENT
          ? sortByColumnSelected.keyOrderAsc
          : sortByColumnSelected.keyOrderDesc,
      );
    } else if (sortByColumnSelected.titleColumn !== sortColumn) {
      handle.changeSorting(sortByColumnSelected.keyOrderDesc);
    }
  };

  const handleExportToExcel = () => {
    const data = assets.map((item) => ({
      Name: item.name,
      Price_As_Of_Date:
        item.priceAsOfDate > 0 ? formatDate(item.priceAsOfDate) : 'N/A',
      Price: item.marketPrice,
      Market_Value: item.marketValue,
      Cost: item.cost,
      Dividends_Received: item.dividendsReceived,
      Total_Profit_and_Loss: item.totalProfitAndLoss,
    }));

    void exportToExcel(data, 'Cash List', 'Clist', [3, 4, 5, 6, 7]);
  };

  return (
    <Container
      parentUrl={backUrl}
      showLayoutHeader={!modalOpen}
      title={pageTitle}
      /*
       * This is a dirty hack that solves an issue on Safari on the mobile
       * viewport where after closing the sort/filter modal the app got stuck
       * on a blue screen.
       *
       * It seems that in that state, removing a css prop forces ionic/safari
       * to show the content correctly.
       *
       * Anyway, this line solves it, we discourage you from touching it or
       * things will break.
       */
      $enableCSSPropAsWorkaround={modalOpen}
    >
      <Grid>
        <Header
          allocation={<Allocation value={allocation} />}
          amount={balance}
          chartData={allocationChartData}
          title={pageTitle}
          modalOpen={modalOpen}
        />
        {!isMobile && (
          <Navigation
            activeLink={selectedUrl}
            breadcrumbs={breadcrumbs}
            links={navigationLinks}
          />
        )}
        <Content>
          {!isMobile && (
            <Toolbar>
              <Title>Liquidity Summary</Title>
              <TableButton
                color="secondary"
                size="small"
                withCaretDown
                href={`${url}/summary${searchWithoutSort(search)}`}
              >
                Liquidity Table
              </TableButton>
            </Toolbar>
          )}
          <Stats
            cashUSD={summary?.cashUSD ?? 0}
            cashOther={summary?.cashOther ?? 0}
            cashEquivalent={summary?.cashEquivalent ?? 0}
            totalCashAvailable={summary?.totalCashAvailable ?? 0}
            USTreasuries={summary?.treasuries ?? 0}
            totalLiquidityAvailable={summary?.totalLiquidityAvailable ?? 0}
            pathname={`${url}/summary${search}`}
            totalCashAllocation={assets.reduce(
              (total, item) => item.allocation + total,
              0,
            )}
          />
          <Toolbar>
            <Title>Cash Assets</Title>
            <ActionZone>
              {isWeb && assets && assets.length > 0 && (
                <ExportToExcelButton
                  size="small"
                  color="secondary"
                  onClick={handleExportToExcel}
                >
                  Export to Excel
                </ExportToExcelButton>
              )}
            </ActionZone>
          </Toolbar>
          <TableContainer>
            <CashContainer>
              <SortInfo
                currentSorting={currentSorting}
                sortOptions={sortOptions}
              />
              <CashList
                data={assets}
                isMobile={isMobile}
                openModal={handle.openModal}
                handleSortByColumn={handleSortByColumn}
                sortApplied={{
                  titleColumn: sortColumn,
                  orderOrientation: sortOrientation as OrderOrientation,
                }}
                headerColumns={headerColumns}
              />
            </CashContainer>
          </TableContainer>
        </Content>
      </Grid>
      {modalOpen && (
        <Formik
          initialValues={initialValues}
          onSubmit={handle.submitSortForm}
          enableReinitialize
        >
          <Form>
            <SortModal onClose={handle.closeModal}>
              <>
                <ModalTitle>Sort by</ModalTitle>
                <SortItemsGroup options={sortOptions} name="value" />
              </>
            </SortModal>
          </Form>
        </Formik>
      )}
    </Container>
  );
};
export default CategoryDetailCash;
