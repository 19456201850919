import type { FC } from 'react';
import { useCallback } from 'react';

import { FilterDate } from '../types';
import { Container, LaptopTabs } from './styles';
import type { Props } from './types';

const filterDateOptions = [
  {
    label: 'Prior Month',
    value: FilterDate.PRIOR_MONTH,
  },
  {
    label: 'Current Month',
    value: FilterDate.CURRENT_MONTH,
  },
  {
    label: 'Prior Year',
    value: FilterDate.PRIOR_YEAR,
  },
  {
    label: 'Current Year',
    value: FilterDate.CURRENT_YEAR,
  },
];

const ControlTabFilterDate: FC<Props> = ({ onChange, selectedFilterDate }) => {
  const handleFilterDateChange = useCallback(
    (filterDate: FilterDate) => {
      onChange(filterDate);
    },
    [onChange],
  );

  return (
    <Container>
      <LaptopTabs<FilterDate>
        onChange={handleFilterDateChange}
        value={selectedFilterDate}
        tabs={filterDateOptions}
      />
    </Container>
  );
};

export default ControlTabFilterDate;
