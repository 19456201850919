import { FC, useEffect, useState } from 'react';

import BaseAssetDetailTable from 'components/BaseAssetDetailTable';
import PrivateInvestmentsTable from 'components/PrivateInvestmentsTable';
import from from 'styles/responsive';
import { formatDate } from 'utils/dates';
import exportToExcel from 'utils/exportToExcel';
import { KeySortOfColumn, OrderOrientation } from 'utils/sort/types';
import useMedia from 'utils/useMedia';
import usePlatform from 'utils/usePlatform/usePlatform';

import useConnect from './connect';
import Controls from './Controls';
import {
  ActionZone,
  Amount,
  Button,
  Chevron,
  Container,
  ContainerControls,
  ContainerMain,
  Content,
  ExportToExcelButton,
  FilterButton,
  FilterLabelContainer,
  GoBackContainer,
  Header,
  Stats,
  StatsTitle,
  Title,
  Toolbar,
} from './styles';

const PrivateInvestmentsSummary: FC = () => {
  const [sortColumn, setSortColumn] = useState<string>('Market Value');
  const [sortOrientation, setSortOrientation] = useState<string>(
    OrderOrientation.DESCENDENT,
  );
  const {
    assets,
    balance,
    mobileRows,
    MOI,
    parentUrl,
    summary,
    subtypes,
    sponsors,
    position,
    handle,
    currentFilteringSubtypes,
    currentFilteringSponsors,
    currentSorting,
    filterSubtypesOptions,
    filterSponsorsOptions,
    headerColumns,
  } = useConnect();

  const isTablet = useMedia(from.tablet.query);
  const isMobile = !useMedia(from.tablet.query);
  const { isWeb } = usePlatform();

  useEffect(() => {
    const currentSortingAux = currentSorting ?? 'default';

    const headerColumn = headerColumns.find(
      (col) =>
        col.keyOrderAsc === currentSortingAux ||
        col.keyOrderDesc === currentSortingAux,
    );

    if (headerColumn) {
      setSortColumn(headerColumn.titleColumn);
      setSortOrientation(
        headerColumn.keyOrderAsc === currentSortingAux
          ? OrderOrientation.ASCENDENT
          : OrderOrientation.DESCENDENT,
      );
    }
  }, [headerColumns, currentSorting]);

  const handleSortByColumn = (sortByColumnSelected: KeySortOfColumn) => {
    if (sortByColumnSelected.titleColumn === sortColumn) {
      handle.changeSorting(
        sortOrientation === OrderOrientation.DESCENDENT
          ? sortByColumnSelected.keyOrderAsc
          : sortByColumnSelected.keyOrderDesc,
      );
    } else if (sortByColumnSelected.titleColumn !== sortColumn) {
      handle.changeSorting(sortByColumnSelected.keyOrderDesc);
    }
  };

  const applyFilterSponsors = (value: string[]) => {
    handle.changeFilteringSponsors(value.join());
  };

  const applyFilterSubtypes = (value: string[]) => {
    handle.changeFilteringSubtypes(value.join());
  };

  const handleExportToExcel = () => {
    const data = assets.map((item) => ({
      Name: item.name,
      Sponsor: item.sponsor,
      Subtype: item.subtype,
      Price_As_Of_Date:
        item.priceAsOfDate > 0 ? formatDate(item.priceAsOfDate) : 'N/A',
      Valuation_Date: item.valueDate > 0 ? formatDate(item.valueDate) : 'N/A',
      Price: item.marketPrice,
      Market_Value: item.value,
      Commitment: item.capitalCommitment,
      Capital_Called: item.capitalCalled,
      To_Be_Called: item.toBeCalled,
      Distributions: item.distributions,
      Profit_and_Loss: item.contDollars,
      MOI: item.moi,
      Status: !item.closedPosition ? 'Open' : 'Closed',
    }));

    void exportToExcel(
      data,
      'Private Investments Table List',
      'PITlist',
      [6, 7, 8, 9, 10, 11, 12],
      [],
      [13],
    );
  };

  return (
    <Container parentUrl={parentUrl} title="Private Investments Summary">
      {isTablet ? (
        <ContainerMain>
          <Header>
            <GoBackContainer>
              <Button
                color="secondary"
                variant="only-icon"
                size="small"
                withIcon={<Chevron />}
                href={`${parentUrl}`}
              />
              <Title>Private Investments Table</Title>
            </GoBackContainer>
            <Amount value={balance} />
          </Header>
          <Content>
            <ContainerControls>
              <StatsTitle>Summary</StatsTitle>
              <Controls
                onChange={handle.changePosition}
                selectedPosition={position}
              />
            </ContainerControls>
            <Stats
              marketValue={summary.value}
              totalPortfolioPercent={summary.allocation}
              commitment={summary.capitalCommitment}
              capitalCalled={summary.grossCapitalCall}
              toBeCalled={summary.toBeCalled}
              distributions={summary.distributions}
              contDollars={summary.contDollars}
              MOI={MOI}
              pathname="#"
            />
            {!isMobile && (
              <Toolbar>
                <ActionZone>
                  {isWeb && assets && assets.length > 0 && (
                    <ExportToExcelButton
                      size="small"
                      color="secondary"
                      onClick={handleExportToExcel}
                    >
                      Export to Excel
                    </ExportToExcelButton>
                  )}
                  <FilterButton
                    onChangeMultiple={applyFilterSponsors}
                    options={filterSponsorsOptions.filter(
                      (opt) => opt.value !== 'default',
                    )}
                    placeholder="Sponsors"
                    value={currentFilteringSponsors}
                    multiple
                  />
                  <FilterButton
                    onChangeMultiple={applyFilterSubtypes}
                    options={filterSubtypesOptions.filter(
                      (opt) => opt.value !== 'default',
                    )}
                    placeholder="Subtypes"
                    value={currentFilteringSubtypes}
                    multiple
                  />
                </ActionZone>
              </Toolbar>
            )}
            <PrivateInvestmentsTable
              data={assets}
              handleSortByColumn={handleSortByColumn}
              sortApplied={{
                titleColumn: sortColumn,
                orderOrientation: sortOrientation as OrderOrientation,
              }}
              headerColumns={headerColumns}
            />
          </Content>
        </ContainerMain>
      ) : (
        <>
          {subtypes && (
            <FilterLabelContainer>
              <div>
                Subtypes filtered by <strong>{subtypes.join(', ')}</strong>
              </div>
            </FilterLabelContainer>
          )}
          {sponsors && (
            <FilterLabelContainer>
              <div>
                Sponsors filtered by <strong>{sponsors.join(', ')}</strong>
              </div>
            </FilterLabelContainer>
          )}
          <Content>
            <BaseAssetDetailTable rows={mobileRows} />
          </Content>
        </>
      )}
    </Container>
  );
};

export default PrivateInvestmentsSummary;
