import type { FC } from 'react';
import { useCallback } from 'react';

import { Container, LaptopTabs } from './styles';
import type { IncomeProfitTypes, Props } from './types';

const incomeProfitTypesOptions = [
  {
    label: 'All',
    value: 'all' as IncomeProfitTypes,
  },
  {
    label: 'Dividends',
    value: 'dividends' as IncomeProfitTypes,
  },
  {
    label: 'Coupons',
    value: 'coupons' as IncomeProfitTypes,
  },
];

const ControlTabProfitIncomeTypes: FC<Props> = ({
  onChange,
  selectedIncomeProfitType,
}) => {
  const handleProfitTypesChange = useCallback(
    (incomeProfitTypes: IncomeProfitTypes) => {
      onChange(incomeProfitTypes);
    },
    [onChange],
  );

  return (
    <Container>
      <LaptopTabs<IncomeProfitTypes>
        onChange={handleProfitTypesChange}
        value={selectedIncomeProfitType}
        tabs={incomeProfitTypesOptions}
      />
    </Container>
  );
};

export default ControlTabProfitIncomeTypes;
