import type { FC } from 'react';
import { useCallback } from 'react';

import FilterYear from '../types';
import { Container, LaptopTabs } from './styles';
import type { Props } from './types';

const filterYearOptions = [
  {
    label: 'Prior Year',
    value: FilterYear.PRIOR_YEAR,
  },
  {
    label: 'Current Year',
    value: FilterYear.CURRENT_YEAR,
  },
  {
    label: 'Next Year',
    value: FilterYear.NEXT_YEAR,
  },
];

const ControlTabFilterYear: FC<Props> = ({ onChange, selectedFilterYear }) => {
  const handleFilterYearChange = useCallback(
    (filterYear: FilterYear) => {
      onChange(filterYear);
    },
    [onChange],
  );

  return (
    <Container>
      <LaptopTabs<FilterYear>
        onChange={handleFilterYearChange}
        value={selectedFilterYear}
        tabs={filterYearOptions}
      />
    </Container>
  );
};

export default ControlTabFilterYear;
