import type { FC } from 'react';
import { useCallback } from 'react';

import { Container, LaptopTabs } from './styles';
import type { IncomeTaxesTypes, Props } from './types';

const incomeTaxesTypesOptions = [
  {
    label: 'All',
    value: 'all' as IncomeTaxesTypes,
  },
  {
    label: 'Taxable',
    value: 'taxable' as IncomeTaxesTypes,
  },
  {
    label: 'Tax Exempt',
    value: 'tax-exempt' as IncomeTaxesTypes,
  },
];

const ControlTabTaxesIncomeTypes: FC<Props> = ({
  onChange,
  selectedIncomeTaxesType,
}) => {
  const handleTaxesTypesChange = useCallback(
    (incomeTaxesTypes: IncomeTaxesTypes) => {
      onChange(incomeTaxesTypes);
    },
    [onChange],
  );

  return (
    <Container>
      <LaptopTabs<IncomeTaxesTypes>
        onChange={handleTaxesTypesChange}
        value={selectedIncomeTaxesType}
        tabs={incomeTaxesTypesOptions}
      />
    </Container>
  );
};

export default ControlTabTaxesIncomeTypes;
