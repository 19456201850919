import { FC, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import useSelectedClient from 'api/hooks/clients/useSelectedClient';

import { ContainerOverlay } from './styles';
import type { Props } from './types';

const CustomDate: FC<Props> = ({
  selectedCustomYear,
  onChange,
  onClear,
  onFocus,
}) => {
  const [customYear, setCustomYear] = useState<Date | null>(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { selectedPortfolio } = useSelectedClient();

  useEffect(() => {
    if (customYear) {
      onChange(customYear);
    }

    if (!customYear) {
      onClear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customYear]);

  useEffect(() => {
    setCustomYear(customYear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomYear]);

  useEffect(() => {
    if (isCalendarOpen) {
      onFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCalendarOpen]);

  return (
    <div>
      {isCalendarOpen && <ContainerOverlay />}
      <DatePicker
        wrapperClassName="react-datepicker-custom"
        placeholderText="Select a year"
        selected={selectedCustomYear}
        minDate={new Date(selectedPortfolio?.inceptionDate ?? 0)}
        maxDate={new Date(selectedPortfolio?.lastUpdated ?? new Date())}
        showYearPicker
        dateFormat="yyyy"
        yearItemNumber={6}
        isClearable
        onChange={(update) => {
          setCustomYear(update);
        }}
        onCalendarOpen={() => {
          setIsCalendarOpen(true);
        }}
        onCalendarClose={() => {
          setIsCalendarOpen(false);
        }}
      />
    </div>
  );
};

export default CustomDate;
