import type { GetEstimatedIncomeResponse } from 'api/queries/incomeProjection/getEstimatedIncome/types';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<
  NonNullable<
    NonNullable<GetEstimatedIncomeResponse['data']>
  >['estimatedIncomeDetails']
>[number];

export const normalizeEstimatedIncomeAsset = (
  input: WithoutNulls<Input>,
  parentId: string,
) =>
  ({
    id: `${parentId}_${input.assetID}_${input.accountID}_${input.transactionID}`,
    date: input.date ? new Date(input.date ?? '') : 0,
    // In back this is typed as nullable string, but it's a nullable number
    amount: (input.amount as unknown as number | undefined | null) ?? 0,
    name: input.assetName ?? '',
    client: input.clientName ?? '',
    account: input.custodian ?? '',
    entity: input.entity ?? '',
    assetClass: input.instrumentType ?? '',
    category: input.category ?? '',
    calculationType: input.calculationType ?? '',
    incomeType: input.incomeType ?? '',
    profitType: input.incomeType?.includes('Coupons') ? 'coupons' : 'dividends',
    taxesType: input.incomeType?.includes('Taxable') ? 'taxable' : 'tax-exempt',
    transactionType: input.transactionType ?? '',
  } as const);

export type EstimatedIncomeAsset = ReturnType<
  typeof normalizeEstimatedIncomeAsset
>;
