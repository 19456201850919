import { defaultStyles, TooltipWithBounds } from '@visx/tooltip';
import type { FC } from 'react';

import Period from 'collections/enums';
import { formatPercentage } from 'utils/percentages';

import { Container, Title, Value } from './styles';
import type { Props } from './types';

const Tooltip: FC<Props> = ({
  tooltipLeft,
  tooltipTop,
  data,
  tooltipData,
  period,
}) => {
  const tooltipStyles = {
    ...defaultStyles,
    backgroundColor: 'inherit',
    boxShadow: 'none',
  };

  return (
    <TooltipWithBounds
      top={tooltipTop + 10}
      left={tooltipLeft}
      style={tooltipStyles}
    >
      <Container>
        <Title>{data[tooltipData.index]?.name}</Title>
        <Value>
          {formatPercentage(
            (period === Period.YEARLY
              ? data[tooltipData.index]?.ytd
              : data[tooltipData.index]?.mtd) || 0,
          )}
        </Value>
      </Container>
    </TooltipWithBounds>
  );
};

export default Tooltip;
