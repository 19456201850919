import { FC, useEffect, useMemo, useState } from 'react';

import { formatDate } from 'utils/dates';
import exportToExcel from 'utils/exportToExcel';
import { getFixedIncomeCharacteristics } from 'utils/fixedIncome';
import getBondPerformanceAnalysis from 'utils/fixedIncome/getBondPerformanceAnalysis';
import { formatPercentageAsBondCoupon } from 'utils/percentages';
import { KeySortOfColumn, OrderOrientation } from 'utils/sort/types';
import usePlatform from 'utils/usePlatform/usePlatform';

import { useDataDisplay } from '../../Content/logic';
import Controls from './Controls';
import type { SummaryType } from './Controls/types';
import {
  ActionZone,
  BPAStats,
  Container,
  ContainerControl,
  DataDisplayButton,
  ExportToExcelButton,
  FilterButton,
  FIStats,
  FixedIncomeAssetTable,
  Title,
  Toolbar,
  YieldDurationChart,
} from './styles';
import type { Props } from './types';

const YieldVsDuration: FC<Props> = ({
  assets,
  cvBondPerformanceSummaries,
  fixedIncomeCharacteristicsUrl,
  bondPerformanceAnalysisUrl,
  currentFiltering,
  currentSorting,
  filterOptions,
  onChangeMultiple,
  onChangeSorting,
  enabledAfterTax = false,
  enabledTaxEquvalentYield = false,
}) => {
  const [control, setControl] = useState<{
    summaryType: SummaryType;
  }>({
    summaryType: 'FIStats',
  });
  const [sortColumn, setSortColumn] = useState<string>('Total');
  const [sortOrientation, setSortOrientation] = useState<string>(
    OrderOrientation.DESCENDENT,
  );

  const { isWeb } = usePlatform();

  const {
    annualIncome,
    annualIncomeAfterTax,
    coupon,
    duration,
    rating,
    timeToMaturity,
    yieldToWorst,
    taxEquivalentYield,
  } = useMemo(() => getFixedIncomeCharacteristics(assets), [assets]);

  const {
    totalCost,
    couponPL,
    bondPL,
    couponsToMaturity,
    recoveryToPar,
    totalFinalPL,
    weightedAverage,
    currBondValue,
  } = useMemo(
    () => getBondPerformanceAnalysis(cvBondPerformanceSummaries),
    [cvBondPerformanceSummaries],
  );

  const headerColumns = useMemo(
    () => [
      {
        titleColumn: `${assets.length} Assets`,
        keyOrderAsc: 'alphabeticalR',
        keyOrderDesc: 'alphabetical',
      },
      {
        titleColumn: 'Total',
        keyOrderAsc: 'totalASC',
        keyOrderDesc: 'default',
      },
      {
        titleColumn: enabledTaxEquvalentYield ? 'TE YTW' : 'YTW',
        keyOrderAsc: 'ytwASC',
        keyOrderDesc: 'ytwDESC',
      },
      {
        titleColumn: 'TTC',
        keyOrderAsc: 'ttcASC',
        keyOrderDesc: 'ttcDESC',
      },
      {
        titleColumn: 'Duration',
        keyOrderAsc: 'durationASC',
        keyOrderDesc: 'durationDESC',
      },
      {
        titleColumn: 'Rating',
        keyOrderAsc: 'ratingASC',
        keyOrderDesc: 'ratingDESC',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enabledTaxEquvalentYield, assets.length],
  );

  useEffect(() => {
    if (enabledTaxEquvalentYield && dataDisplay === 'YTW') {
      onChangeDataDisplay('TE YTW');
    } else if (!enabledTaxEquvalentYield && dataDisplay === 'TE YTW') {
      onChangeDataDisplay('YTW');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledTaxEquvalentYield]);

  useEffect(() => {
    const currentSortingAux = currentSorting ?? 'default';

    const headerColumn = headerColumns.find(
      (col) =>
        col.keyOrderAsc === currentSortingAux ||
        col.keyOrderDesc === currentSortingAux,
    );

    if (headerColumn) {
      setSortColumn(headerColumn.titleColumn);
      setSortOrientation(
        headerColumn.keyOrderAsc === currentSortingAux
          ? OrderOrientation.ASCENDENT
          : OrderOrientation.DESCENDENT,
      );
    }
  }, [headerColumns, currentSorting]);

  const handleSortByColumn = (sortByColumnSelected: KeySortOfColumn) => {
    if (sortByColumnSelected.titleColumn === sortColumn) {
      onChangeSorting(
        sortOrientation === OrderOrientation.DESCENDENT
          ? sortByColumnSelected.keyOrderAsc
          : sortByColumnSelected.keyOrderDesc,
      );
    } else if (sortByColumnSelected.titleColumn !== sortColumn) {
      onChangeSorting(sortByColumnSelected.keyOrderDesc);
    }
  };

  const handleExportToExcel = () => {
    const data = assets.map((asset) => ({
      Isin_Ticker: asset.isinTicker ? asset.isinTicker : 'N/A',
      Name: asset.fullName,
      Nominal_Amount: asset.shares,
      Accrued_Interest: asset.accruedInterest,
      Price_As_Of_Date:
        asset.priceAsOfDate > 0 ? formatDate(asset.priceAsOfDate) : 'N/A',
      Price: asset.marketPrice,
      Market_Value: asset.marketValue,
      Cost: asset.cost,
      Accrued_Interest_at_Purchase: asset.aIatPurchase,
      Profit_and_Loss_Excluding_Coupons: asset.pnLExclCpn,
      Coupons_Received: asset.couponsReceived,
      Total_Profit_and_Loss: asset.totalProfitAndLoss,
      Yield_to_Worst: asset.ytw,
      Coupon: asset.coupon,
      Time_to_Call: asset.ttc,
      Duration: asset.duration,
      Maturity_Date: formatDate(asset.maturityDate),
      Rating: asset.rating,
      Rtg_MOODY: asset.rtgMOODY,
      Rtg_SP: asset.rtgSP,
      Rtg_FITCH: asset.rtgFITCH,
    }));

    void exportToExcel(
      data,
      'Fixed Income List',
      'FIlist',
      [4, 6, 7, 8, 9, 10, 11, 12],
      [13, 14],
      [3, 15, 16],
    );
  };

  const { dataDisplay, dataDisplayOptions, onChangeDataDisplay } =
    useDataDisplay(enabledTaxEquvalentYield);

  const chartAssets = useMemo(
    () =>
      assets
        .map(({ coupon: rawCoupon, maturity, name, ...asset }) => ({
          ...asset,
          name: `${name} ${formatPercentageAsBondCoupon(
            rawCoupon,
          )} ${maturity}`,
        }))
        .sort((a, b) => a.duration - b.duration),
    [assets],
  );

  return (
    <Container>
      <YieldDurationChart
        data={chartAssets}
        durationAverage={duration}
        yieldToWorstAverage={
          enabledTaxEquvalentYield ? taxEquivalentYield : yieldToWorst
        }
        enabledTaxEquvalentYield={enabledTaxEquvalentYield}
      />
      {cvBondPerformanceSummaries.length > 0 ? (
        <ContainerControl>
          <Controls
            onChange={setControl}
            selectedSummaryType={control.summaryType}
          />
        </ContainerControl>
      ) : (
        <Title>Fixed Income Characteristics</Title>
      )}
      {(control.summaryType === 'FIStats' ||
        cvBondPerformanceSummaries.length === 0) && (
        <FIStats
          annualIncome={enabledAfterTax ? annualIncomeAfterTax : annualIncome}
          coupon={coupon}
          duration={duration}
          pathname={fixedIncomeCharacteristicsUrl}
          rating={rating}
          timeToMaturity={timeToMaturity}
          yieldToWorst={
            enabledTaxEquvalentYield ? taxEquivalentYield : yieldToWorst
          }
          enabledTaxEquvalentYield={enabledTaxEquvalentYield}
        />
      )}
      {control.summaryType === 'BPAStats' &&
        cvBondPerformanceSummaries.length > 0 && (
          <BPAStats
            pathname={bondPerformanceAnalysisUrl}
            totalCost={totalCost}
            couponPL={couponPL}
            bondPL={bondPL}
            couponsToMaturity={couponsToMaturity}
            recoveryToPar={recoveryToPar}
            totalFinalPL={totalFinalPL}
            weightedAverage={weightedAverage}
            currBondValue={currBondValue}
          />
        )}
      <Toolbar>
        <Title>Fixed Income Securities</Title>
        <ActionZone>
          <DataDisplayButton
            options={dataDisplayOptions}
            onChange={onChangeDataDisplay}
            value={dataDisplay}
          />
          {isWeb && assets && assets.length > 0 && (
            <ExportToExcelButton
              size="small"
              color="secondary"
              onClick={handleExportToExcel}
            >
              Export to Excel
            </ExportToExcelButton>
          )}
          <FilterButton
            onChangeMultiple={onChangeMultiple}
            options={filterOptions.filter((opt) => opt.value !== 'default')}
            placeholder="Filter"
            value={currentFiltering}
            multiple
          />
        </ActionZone>
      </Toolbar>
      <FixedIncomeAssetTable
        data={assets}
        selectedData={dataDisplay}
        enabledTaxEquivalentYield={enabledTaxEquvalentYield}
        handleSortByColumn={handleSortByColumn}
        sortApplied={{
          titleColumn: sortColumn,
          orderOrientation: sortOrientation as OrderOrientation,
        }}
        headerColumns={headerColumns}
      />
    </Container>
  );
};

export default YieldVsDuration;
