import styled from 'styled-components';

import { ReactComponent as NotFoundDataIcon } from 'assets/icons/icon-not-found-data.svg';
import { ReactComponent as DefaultMinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as DefaultPlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DefaultVectorDownIcon } from 'assets/icons/vector-down.svg';
import { ReactComponent as DefaultVectorUpIcon } from 'assets/icons/vector-up.svg';
import DefaultAmount from 'components/Amount';
import DefaultSpinner from 'components/Spinner';
import from from 'styles/responsive';

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;

  div {
    margin: 3rem;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Table = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.75rem;
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colors.white};

  > :nth-child(even) {
    background: ${({ theme }) => theme.colors.dark1};
  }

  ${from.laptop`
    font-size: 0.875rem;
  `}
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 0.4fr 1fr 0.4fr 0.7fr 1fr 0.4fr 1.5rem;
  column-gap: 1rem;
  padding: 1rem;
  color: ${({ theme }) => theme.colors.white75};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  text-align: left;
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.colors.dark2};

  > :nth-child(6) {
    text-align: right;
  }
`;

export const HeaderCell = styled.div`
  font-size: 0.75rem;
  line-height: 1.0625rem;

  ${from.laptop`
    font-size: 0.875rem;
  `}
`;

export const HeaderTitle = styled.span`
  cursor: pointer;
  display: inline-flex;
`;

export const Title = styled.span`
  width: max-content;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 0.4fr 1fr 0.4fr 0.7fr 1fr 0.4fr 1.5rem;
  column-gap: 1rem;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
  padding: 1rem;
`;

export const ColLeft = styled.div`
  text-align: left;
`;

export const ColMoreInfo = styled.div`
  cursor: pointer;
  text-align: center;
  height: 1.5rem;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;

  &:hover {
    background: ${({ theme }) => theme.colors.primary50};
  }
`;

export const ColouredAmount = styled(DefaultAmount).attrs({ coloured: true })`
  text-align: right;
`;

export const MoreInfo = styled.div`
  padding: 1rem;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
`;

export const MoreInfoRow = styled.div`
  display: flex;
  margin: 1rem 3rem;
`;

export const MoreInfoTitle = styled.div`
  margin-right: 4rem;
  min-width: 8rem;
  font-weight: ${({ theme }) => theme.weights.medium};
`;
export const MoreInfoValue = styled.div`
  text-transform: capitalize;
`;

export const NotFoundDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
`;

export const NotFoundDataImg = styled(NotFoundDataIcon)`
  width: 100%;
  height: 320px;
`;

export const VectorDownIcon = styled(DefaultVectorDownIcon)``;

export const VectorUpIcon = styled(DefaultVectorUpIcon)``;

export const PlusIcon = styled(DefaultPlusIcon)``;

export const MinusIcon = styled(DefaultMinusIcon)``;

export const Spinner = styled(DefaultSpinner)``;
