import groupBy from 'lodash/groupBy';
import { useCallback } from 'react';
import type { QueryFunction } from 'react-query';

import useRequest from 'api/request';
import {
  EstimatedIncomeMonth,
  normalizeEstimatedIncomeMonth,
} from 'model/EstimatedIncomeMonth';
import getPortfolioId from 'utils/getPorfolioId';
import withoutNulls from 'utils/withoutNulls';

import type {
  GetEstimatedIncomeRequest,
  GetEstimatedIncomeResponse,
} from './types';

export const GET_ESTIMATED_INCOME_QUERY_KEY = (
  portfolio: string,
  year: number,
) => ['transactions/getEstimatedIncome', portfolio, year];

export default function useGetEstimatedIncomeQuery(): QueryFunction<
  EstimatedIncomeMonth[]
> {
  const request = useRequest<
    GetEstimatedIncomeRequest,
    GetEstimatedIncomeResponse
  >();

  return useCallback(
    async ({ queryKey }) => {
      const [, portfolio, year] = queryKey as [string, string, number];

      const response = await request(
        '/api/CVData/getEstimatedIncome/{portfolio}/{year}',
        {
          urlParams: {
            portfolio: getPortfolioId(portfolio) ?? '',
            year: year.toString(),
          },
        },
      );

      const data = await response.json();

      if (!data.isSuccess) {
        throw new Error(data.message ?? '');
      }

      const grouped = groupBy(
        data.data?.estimatedIncomeDetails ?? [],
        (item) => item.eomonthDate,
      );

      return withoutNulls(Object.values(grouped) ?? []).map(
        normalizeEstimatedIncomeMonth,
      );
    },
    [request],
  );
}
