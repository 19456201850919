import { useCallback, useEffect, useState } from 'react';

import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import useEstimatedIncome from 'api/hooks/incomeProjection/useEstimatedIncome';
import type { EstimatedIncomeAsset } from 'model/EstimatedIncomeAsset';
import type { EstimatedIncomeMonth } from 'model/EstimatedIncomeMonth';
import type { KeySortOfColumn } from 'utils/sort/types';

import type { IncomeProfitTypes } from './ControlTabProfitTypes/types';
import type { IncomeTaxesTypes } from './ControlTabTaxesTypes/types';
import {
  applyAccountFilter,
  applyEntityFilter,
  applyProfitTypeFilter,
  applyTaxesTypeFilter,
  getAccountsOptions,
  getEntitiesOptions,
  getIncomeProjectionYear,
  useDateSelection,
  useSorting,
} from './logic';
import FilterYear from './types';

export default function useConnect() {
  const { selectedPortfolio } = useSelectedClient();
  const [loading, setLoading] = useState<boolean>(true);

  const { currentSorting, onChangeSorting, compare } = useSorting();
  const [filterEntity, setFilterEntity] = useState<string[] | undefined>(
    undefined,
  );
  const [filterAccount, setFilterAccount] = useState<string[] | undefined>(
    undefined,
  );

  const [incomeProfitType, setIncomeProfitType] =
    useState<IncomeProfitTypes>('all');
  const [incomeTaxesType, setIncomeTaxesType] =
    useState<IncomeTaxesTypes>('all');

  const [filterYear, setFilterYear] = useState<FilterYear>(
    FilterYear.CURRENT_YEAR,
  );
  const [customYear, setCustomYear] = useState<Date | undefined>(undefined);

  const [incomeProjectionDataFiltered, setIncomeProjectionDataFiltered] =
    useState<EstimatedIncomeMonth[]>([]);
  const [incomeProjectionDetailSorted, setIncomeProjectionDetailSorted] =
    useState<EstimatedIncomeAsset[] | undefined>(undefined);

  const { data: incomeProjection } = useEstimatedIncome(
    selectedPortfolio?.id,
    getIncomeProjectionYear(customYear?.getFullYear(), filterYear),
  );

  useEffect(() => {
    if (selectedPortfolio?.id && incomeProjection) {
      setLoading(false);
      setIncomeProjectionDataFiltered(incomeProjection ?? []);
    } else {
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterYear, incomeProjection, selectedPortfolio]);

  useEffect(() => {
    if (selectedPortfolio?.id) {
      onResetFilterLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPortfolio]);

  useEffect(() => {
    if (incomeProjection) {
      setLoading(false);
      let dataFiltered = incomeProjection;
      dataFiltered = applyProfitTypeFilter(dataFiltered, incomeProfitType);
      dataFiltered = applyTaxesTypeFilter(dataFiltered, incomeTaxesType);
      dataFiltered = applyEntityFilter(dataFiltered, filterEntity);
      dataFiltered = applyAccountFilter(dataFiltered, filterAccount);
      setIncomeProjectionDataFiltered([...dataFiltered]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeProfitType, incomeTaxesType, filterEntity, filterAccount]);

  const { dateOptions, incomeProjectionDetail, selectedDate, setSelectedDate } =
    useDateSelection({
      incomeProjectionDataFiltered,
    });

  useEffect(() => {
    if (incomeProjectionDetail) {
      setIncomeProjectionDetailSorted(incomeProjectionDetail.sort(compare));
    } else {
      setIncomeProjectionDetailSorted(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeProjectionDetail]);

  useEffect(() => {
    if (selectedPortfolio?.id && incomeProjectionDetailSorted) {
      setIncomeProjectionDetailSorted(
        incomeProjectionDetailSorted.sort(compare),
      );
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSorting]);

  const onChangeFilteringEntity = useCallback(
    (newValue: string[] | undefined) => {
      if (newValue?.length === 0 || newValue === undefined) {
        setFilterEntity(undefined);
      } else {
        setFilterEntity(newValue);
      }
    },
    [setFilterEntity],
  );

  const onChangeFilteringAccount = useCallback(
    (newValue: string[] | undefined) => {
      if (newValue?.length === 0 || newValue === undefined) {
        setFilterAccount(undefined);
      } else {
        setFilterAccount(newValue);
      }
    },
    [setFilterAccount],
  );

  const onChangeSelectedDate = useCallback(
    (newValue: string) => {
      setSelectedDate(newValue);
    },
    [setSelectedDate],
  );

  const onChangeIncomeProfitType = (value: IncomeProfitTypes) => {
    setIncomeProfitType(value);
  };

  const onChangeIncomeTaxesType = (value: IncomeTaxesTypes) => {
    setIncomeTaxesType(value);
  };

  const onChangeFilterYear = (value: FilterYear) => {
    setFilterYear(value);
    setCustomYear(undefined);
    setSelectedDate('summary');
  };

  const onChangeCustomYear = (value: Date | undefined) => {
    setFilterYear(FilterYear.CUSTOM_YEAR);
    setCustomYear(value);
    setSelectedDate('summary');
  };

  const onClearCustomYear = () => {
    setFilterYear(FilterYear.CURRENT_YEAR);
    setCustomYear(undefined);
  };

  const onResetFilterLink = () => {
    onChangeIncomeProfitType('all');
    onChangeIncomeTaxesType('all');
    onChangeFilteringEntity(undefined);
    onChangeFilteringAccount(undefined);
    onChangeFilterYear(FilterYear.CURRENT_YEAR);
    onChangeSelectedDate('summary');
  };

  const headerColumns: KeySortOfColumn[] = [
    {
      titleColumn: 'Name',
      keyOrderAsc: 'nameASC',
      keyOrderDesc: 'default',
    },
    {
      titleColumn: 'Entity',
      keyOrderAsc: 'entityASC',
      keyOrderDesc: 'entityDESC',
    },
    {
      titleColumn: 'Account',
      keyOrderAsc: 'accountASC',
      keyOrderDesc: 'accountDESC',
    },
    {
      titleColumn: 'Category',
      keyOrderAsc: 'categoryASC',
      keyOrderDesc: 'categoryDESC',
    },
    {
      titleColumn: 'Amount',
      keyOrderAsc: 'amountASC',
      keyOrderDesc: 'amountDESC',
    },
  ];

  return {
    incomeProjectionData: incomeProjectionDataFiltered,
    incomeProjectionDetail: incomeProjectionDetailSorted,
    filterEntity,
    filterAccount,
    entitiesOptions: getEntitiesOptions(incomeProjection ?? []),
    accountsOptions: getAccountsOptions(incomeProjection ?? []),
    dateOptions,
    selectedDate,
    headerColumns,
    currentSorting,
    incomeProfitType,
    incomeTaxesType,
    filterYear,
    customYear,
    handle: {
      onChangeSelectedDate,
      onChangeFilteringEntity,
      onChangeFilteringAccount,
      onChangeSorting,
      onChangeIncomeProfitType,
      onChangeIncomeTaxesType,
      onChangeFilterYear,
      onChangeCustomYear,
      onClearCustomYear,
      onResetFilterLink,
    },
    loading,
  };
}
