import { parseISO } from 'date-fns';

import type { GetIncomeProfileR12Response } from 'api/queries/allocations/getIncomeProfileR12/types';
import unreachable from 'utils/unreachable';
import type { WithoutNulls } from 'utils/withoutNulls';

import {
  normalizeIncomeProfileAsset,
  normalizeIncomeProfileAssetAfterTax,
} from './IncomeProfileAsset';

type Input = NonNullable<GetIncomeProfileR12Response['data']>[number];

export const normalizeIncomeProfileMonth = (input: WithoutNulls<Input[]>) => {
  const id = input[0]?.monthDate ?? '';

  return {
    assets: input.map((asset) => normalizeIncomeProfileAsset(asset, id)) ?? [],
    assetsAfterTax:
      input.map((asset) => normalizeIncomeProfileAssetAfterTax(asset, id)) ??
      [],
    date: parseISO(input[0]?.monthDate ?? unreachable('1970-01-01')).getTime(),
    id,
    income: input.reduce(
      (sum, asset) => Number(sum) + Number(asset.incomeStream ?? 0),
      0,
    ),
    incomeAfterTax: input.reduce(
      (sum, asset) => Number(sum) + Number(asset.incomeStreamAfterTax ?? 0),
      0,
    ),
  } as const;
};

export type IncomeProfileMonth = ReturnType<typeof normalizeIncomeProfileMonth>;
