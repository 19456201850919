import { Formik } from 'formik';
import { FC, useEffect, useMemo, useState } from 'react';

import SortItemsGroup from 'components/SortItemsGroup';
import from from 'styles/responsive';
import { getFixedIncomeCharacteristics } from 'utils/fixedIncome';
import getBondPerformanceAnalysis from 'utils/fixedIncome/getBondPerformanceAnalysis';
import { KeySortOfColumn, OrderOrientation } from 'utils/sort/types';
import useMedia from 'utils/useMedia';

import { useDataDisplay } from './logic';
import {
  ActionZone,
  BPAStats,
  Container,
  DataDisplayButton,
  FilterButton,
  FilterModalTitle,
  FIStats,
  FixedIncomeAssetTable,
  Form,
  Modal,
  ModalTitle,
  SortInfo,
  SortMobileButton,
  TableContainer,
  Title,
  Toolbar,
} from './styles';
import type { Props } from './types';

const Content: FC<Props> = ({
  assets,
  cvBondPerformanceSummaries,
  fixedIncomeCharacteristicsUrl,
  bondPerformanceAnalysisUrl,
  currentFiltering,
  currentSorting,
  filterOptions,
  initialValues,
  modalOpen,
  onChangeMultiple,
  onChangeSorting,
  onCloseModal,
  onOpenModal,
  onSubmitForm,
  sortOptions,
  showBondPerformanceAnalysis,
}) => {
  const [sortColumn, setSortColumn] = useState<string>('Total');
  const [sortOrientation, setSortOrientation] = useState<string>('DESC');

  const {
    annualIncome,
    coupon,
    duration,
    rating,
    timeToMaturity,
    yieldToWorst,
  } = useMemo(() => getFixedIncomeCharacteristics(assets), [assets]);

  const {
    totalCost,
    couponPL,
    bondPL,
    couponsToMaturity,
    recoveryToPar,
    totalFinalPL,
    weightedAverage,
    currBondValue,
  } = useMemo(
    () => getBondPerformanceAnalysis(cvBondPerformanceSummaries),
    [cvBondPerformanceSummaries],
  );

  const { dataDisplay, dataDisplayOptions, onChangeDataDisplay } =
    useDataDisplay();

  const isMobile = !useMedia(from.tablet.query);

  const headerColumns = useMemo(
    () => [
      {
        titleColumn: `${assets.length} Assets`,
        keyOrderAsc: 'alphabeticalR',
        keyOrderDesc: 'alphabetical',
      },
      {
        titleColumn: 'Total',
        keyOrderAsc: 'totalASC',
        keyOrderDesc: 'default',
      },
      {
        titleColumn: 'YTW',
        keyOrderAsc: 'ytwASC',
        keyOrderDesc: 'ytwDESC',
      },
      {
        titleColumn: 'TTC',
        keyOrderAsc: 'ttcASC',
        keyOrderDesc: 'ttcDESC',
      },
      {
        titleColumn: 'Duration',
        keyOrderAsc: 'durationASC',
        keyOrderDesc: 'durationDESC',
      },
      {
        titleColumn: 'Rating',
        keyOrderAsc: 'ratingASC',
        keyOrderDesc: 'ratingDESC',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assets.length],
  );

  useEffect(() => {
    const currentSortingAux = currentSorting ?? 'default';

    const headerColumn = headerColumns.find(
      (col) =>
        col.keyOrderAsc === currentSortingAux ||
        col.keyOrderDesc === currentSortingAux,
    );

    if (headerColumn) {
      setSortColumn(headerColumn.titleColumn);
      setSortOrientation(
        headerColumn.keyOrderAsc === currentSortingAux
          ? OrderOrientation.ASCENDENT
          : OrderOrientation.DESCENDENT,
      );
    }
  }, [headerColumns, currentSorting]);

  const handleSortByColumn = (sortByColumnSelected: KeySortOfColumn) => {
    if (sortByColumnSelected.titleColumn === sortColumn) {
      onChangeSorting(
        sortOrientation === 'DESC'
          ? sortByColumnSelected.keyOrderAsc
          : sortByColumnSelected.keyOrderDesc,
      );
    } else if (sortByColumnSelected.titleColumn !== sortColumn) {
      onChangeSorting(sortByColumnSelected.keyOrderDesc);
    }
  };

  return (
    <Container>
      {!isMobile && <Title>Fixed Income Characteristics</Title>}
      <FIStats
        annualIncome={annualIncome}
        coupon={coupon}
        duration={duration}
        pathname={fixedIncomeCharacteristicsUrl}
        rating={rating}
        timeToMaturity={timeToMaturity}
        yieldToWorst={yieldToWorst}
      />
      {isMobile &&
        cvBondPerformanceSummaries.length > 0 &&
        showBondPerformanceAnalysis && (
          <BPAStats
            pathname={bondPerformanceAnalysisUrl}
            totalCost={totalCost}
            couponPL={couponPL}
            bondPL={bondPL}
            couponsToMaturity={couponsToMaturity}
            recoveryToPar={recoveryToPar}
            totalFinalPL={totalFinalPL}
            weightedAverage={weightedAverage}
            currBondValue={currBondValue}
          />
        )}
      <SortInfo
        currentFilteringSubtypes={currentFiltering}
        currentSorting={currentSorting}
        filterSubtypesOptions={filterOptions}
        sortOptions={sortOptions}
      />
      <Toolbar>
        <Title>
          {isMobile ? `${assets.length} Assets` : 'Fixed Income Securities'}
        </Title>
        <ActionZone>
          <DataDisplayButton
            options={dataDisplayOptions}
            onChange={onChangeDataDisplay}
            value={dataDisplay}
          />
          <SortMobileButton
            color="secondary"
            size="small"
            onClick={onOpenModal}
          >
            Sort/Filter
          </SortMobileButton>
          {!isMobile && (
            <FilterButton
              onChangeMultiple={onChangeMultiple}
              options={filterOptions.filter((opt) => opt.value !== 'default')}
              placeholder="Filter"
              value={currentFiltering}
              multiple
            />
          )}
        </ActionZone>
      </Toolbar>
      <TableContainer>
        <FixedIncomeAssetTable
          data={assets}
          selectedData={dataDisplay}
          handleSortByColumn={handleSortByColumn}
          sortApplied={{
            titleColumn: sortColumn,
            orderOrientation: sortOrientation as OrderOrientation,
          }}
          headerColumns={headerColumns}
        />
      </TableContainer>
      {modalOpen && (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitForm}
          enableReinitialize
        >
          <Form>
            <Modal onClose={onCloseModal}>
              <>
                <ModalTitle>Sort by</ModalTitle>
                <SortItemsGroup options={sortOptions} name="sortValue" />
                <FilterModalTitle>Filter</FilterModalTitle>
                <SortItemsGroup
                  options={filterOptions}
                  name="filterValue"
                  multiple
                />
              </>
            </Modal>
          </Form>
        </Formik>
      )}
    </Container>
  );
};
export default Content;
