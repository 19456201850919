/* eslint-disable no-param-reassign */
import uniq from 'lodash/uniq';
import { useMemo, useState } from 'react';

import type { EstimatedIncomeAsset } from 'model/EstimatedIncomeAsset';
import type { EstimatedIncomeMonth } from 'model/EstimatedIncomeMonth';
import { formatMonthYear } from 'utils/dates';
import useEntitySorting, {
  SortOption,
} from 'utils/useDataManipulation/useEntitySorting';

import type { IncomeProfitTypes } from './ControlTabProfitTypes/types';
import type { IncomeTaxesTypes } from './ControlTabTaxesTypes/types';
import FilterYear from './types';

export type Option = {
  label: string;
  value: string;
};

export function getEntitiesOptions(data: EstimatedIncomeMonth[]) {
  const entities = uniq(
    data
      .map((dataMonth) =>
        dataMonth.assets
          .filter((item) => item.entity !== '')
          .map((item) => item.entity),
      )
      .flat(),
  );
  return [
    { value: 'default', label: 'All' },
    ...entities.map((item) => ({ value: item, label: item })),
  ];
}

export function getAccountsOptions(data: EstimatedIncomeMonth[]) {
  const accounts = uniq(
    data
      .map((dataMonth) =>
        dataMonth.assets
          .filter((item) => item.account !== '')
          .map((item) => item.account),
      )
      .flat(),
  );
  return [
    { value: 'default', label: 'All' },
    ...accounts.map((item) => ({ value: item, label: item })),
  ];
}

export function applyProfitTypeFilter(
  data: EstimatedIncomeMonth[],
  filter: IncomeProfitTypes,
) {
  return data.map((dataMonth) => {
    const assets =
      filter !== 'all'
        ? dataMonth.assets.filter((item) => item.profitType === filter)
        : dataMonth.assets;
    return {
      id: dataMonth.id,
      eomonthDate: dataMonth.eomonthDate,
      assets,
      amount: assets.reduce(
        (sum, asset) => Number(sum) + Number(asset.amount ?? 0),
        0,
      ),
    };
  });
}

export function applyTaxesTypeFilter(
  data: EstimatedIncomeMonth[],
  filter: IncomeTaxesTypes,
) {
  return data.map((dataMonth) => {
    const assets =
      filter !== 'all'
        ? dataMonth.assets.filter((item) => item.taxesType === filter)
        : dataMonth.assets;
    return {
      id: dataMonth.id,
      eomonthDate: dataMonth.eomonthDate,
      assets,
      amount: assets.reduce(
        (sum, asset) => Number(sum) + Number(asset.amount ?? 0),
        0,
      ),
    };
  });
}

export function applyEntityFilter(
  data: EstimatedIncomeMonth[],
  filterEntity: string[] | undefined,
) {
  if (filterEntity !== undefined && filterEntity.length !== 0) {
    return data.map((dataMonth) => {
      const assets = dataMonth.assets.filter((item) =>
        filterEntity.includes(item.entity),
      );
      return {
        id: dataMonth.id,
        eomonthDate: dataMonth.eomonthDate,
        assets,
        amount: assets.reduce(
          (sum, asset) => Number(sum) + Number(asset.amount ?? 0),
          0,
        ),
      };
    });
  }

  return data;
}

export function applyAccountFilter(
  data: EstimatedIncomeMonth[],
  filterAccount: string[] | undefined,
) {
  if (filterAccount !== undefined && filterAccount.length !== 0) {
    return data.map((dataMonth) => {
      const assets = dataMonth.assets.filter((item) =>
        filterAccount.includes(item.account),
      );
      return {
        id: dataMonth.id,
        eomonthDate: dataMonth.eomonthDate,
        assets,
        amount: assets.reduce(
          (sum, asset) => Number(sum) + Number(asset.amount ?? 0),
          0,
        ),
      };
    });
  }

  return data;
}

export function useDateSelection({
  incomeProjectionDataFiltered,
}: {
  incomeProjectionDataFiltered: readonly EstimatedIncomeMonth[];
}) {
  const dateOptions = useMemo(
    (): readonly [Option, ...Option[]] => [
      {
        label: 'Summary',
        value: 'summary',
      },
      ...incomeProjectionDataFiltered.map(({ eomonthDate }) => ({
        value: `${eomonthDate}`,
        label: `${formatMonthYear(eomonthDate)}`,
      })),
    ],
    [incomeProjectionDataFiltered],
  );

  const [selectedDate, setSelectedDate] = useState(dateOptions[0].value);

  const incomeProjectionDetail = useMemo(
    () =>
      selectedDate === 'summary'
        ? undefined
        : incomeProjectionDataFiltered
            .find((month) => `${month.eomonthDate}` === selectedDate)
            ?.assets?.sort((a, b) => a.name.localeCompare(b.name)),
    [incomeProjectionDataFiltered, selectedDate],
  );

  return {
    dateOptions,
    incomeProjectionDetail,
    selectedDate,
    setSelectedDate,
  };
}

type AssetSortOption = SortOption<EstimatedIncomeAsset>;

export function useSorting() {
  const sortOptions: readonly [AssetSortOption, ...AssetSortOption[]] = useMemo(
    () => [
      {
        compare: (a, b) => a.name.localeCompare(b.name),
        label: 'Name: A to Z',
        value: 'default',
      },
      {
        compare: (a, b) => b.name.localeCompare(a.name),
        label: 'Name: Z to A',
        value: 'nameASC',
      },
      {
        compare: (a, b) => a.entity.localeCompare(b.entity),
        label: 'Entity: A to Z',
        value: 'entityDESC',
      },
      {
        compare: (a, b) => b.entity.localeCompare(a.entity),
        label: 'Entity: Z to A',
        value: 'entityASC',
      },
      {
        compare: (a, b) => a.account.localeCompare(b.account),
        label: 'Account: A to Z',
        value: 'accountDESC',
      },
      {
        compare: (a, b) => b.account.localeCompare(a.account),
        label: 'Account: Z to A',
        value: 'accountASC',
      },
      {
        compare: (a, b) => a.category.localeCompare(b.category),
        label: 'Category: A to Z',
        value: 'categoryDESC',
      },
      {
        compare: (a, b) => b.category.localeCompare(a.category),
        label: 'Category: Z to A',
        value: 'categoryASC',
      },
      {
        compare: (a, b) => b.amount - a.amount,
        label: 'Amount: High to Low',
        value: 'amountDESC',
      },
      {
        compare: (a, b) => a.amount - b.amount,
        label: 'Amount: Low to High',
        value: 'amountASC',
      },
    ],
    [],
  );

  return useEntitySorting({ sortOptions });
}

export function getIncomeProjectionYear(
  year: number | undefined,
  filterYear: FilterYear | undefined,
) {
  let yearParse = new Date().getFullYear();

  if (year) {
    yearParse = year;
  } else {
    switch (filterYear) {
      case FilterYear.CUSTOM_YEAR:
        yearParse = new Date().getFullYear();
        break;
      case FilterYear.PRIOR_YEAR:
        yearParse = new Date().getFullYear() - 1;
        break;
      case FilterYear.NEXT_YEAR:
        yearParse = new Date().getFullYear() + 1;
        break;
      default:
        break;
    }
  }

  return yearParse;
}

export function calculateIncomeTypeAmount(data: EstimatedIncomeMonth[]) {
  return data.map((item) => {
    const incomeTypeAmount = item.assets.reduce((acc, asset) => {
      if (!acc[asset.incomeType]) {
        acc[asset.incomeType] = 0;
      }

      acc[asset.incomeType] = (acc[asset.incomeType] || 0) + asset.amount;

      return acc;
    }, {} as Record<string, number>);

    return {
      id: item.id,
      date: item.eomonthDate,
      amount: item.amount,
      ...incomeTypeAmount,
    };
  });
}
