import { Formik } from 'formik';
import { FC, useEffect, useMemo, useState } from 'react';

import ChangePercentage from 'components/ChangePercentage';
import ReportCategoryDetailTable from 'components/ReportCategoryDetailTable';
import Navigation from 'components/SectionNavigation';
import SortItemsGroup from 'components/SortItemsGroup';
import SortModal from 'components/SortModal';
import from from 'styles/responsive';
import { KeySortOfColumn, OrderOrientation } from 'utils/sort/types';
import useMedia from 'utils/useMedia';

import useConnect from './connect';
import {
  Container,
  Content,
  Form,
  Header,
  HeaderAmount,
  HeaderTitleContainer,
  LoaderContainer,
  MainGrid,
  ModalTitle,
  ROCTitle,
  RowContainer,
  SortMobileButton,
  Spinner,
  Subtitle,
  Title,
  TitleTable,
  Toolbar,
} from './styles';

const ReportCategoryDetail: FC = () => {
  const [sortColumn, setSortColumn] = useState<string>('Total ($)');
  const [sortOrientation, setSortOrientation] = useState<string>(
    OrderOrientation.DESCENDENT,
  );
  const {
    breadcrumbs,
    category,
    currentSorting,
    handle,
    initialValues,
    modalOpen,
    navLinks,
    report,
    headerTimeFrameText,
    sortOptions,
    headerColumns,
    url,
  } = useConnect();
  const isTablet = useMedia(from.tablet.query);
  const showRoc = useMemo(() => !url.includes('since-inception'), [url]);

  useEffect(() => {
    const currentSortingAux = currentSorting ?? 'default';

    const headerColumn = headerColumns.find(
      (col) =>
        col.keyOrderAsc === currentSortingAux ||
        col.keyOrderDesc === currentSortingAux,
    );

    if (headerColumn) {
      setSortColumn(headerColumn.titleColumn);
      setSortOrientation(
        headerColumn.keyOrderAsc === currentSortingAux
          ? OrderOrientation.ASCENDENT
          : OrderOrientation.DESCENDENT,
      );
    }
  }, [headerColumns, currentSorting]);

  const reportNameText = () => {
    switch (report?.name) {
      case 'Month To Date':
        return 'Monthly Estimate';
      case 'Year To Date':
        return 'Yearly Estimate';
      case 'Daily Estimate':
      case 'Since Inception':
        return report.name;
      default:
        return '';
    }
  };

  const handleSortByColumn = (sortByColumnSelected: KeySortOfColumn) => {
    if (sortByColumnSelected.titleColumn === sortColumn) {
      handle.changeSorting(
        sortOrientation === OrderOrientation.DESCENDENT
          ? sortByColumnSelected.keyOrderAsc
          : sortByColumnSelected.keyOrderDesc,
      );
    } else if (sortByColumnSelected.titleColumn !== sortColumn) {
      handle.changeSorting(sortByColumnSelected.keyOrderDesc);
    }
  };

  return (
    <>
      {(!category || !report) && (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      )}
      <Container
        $loading={!category || !report}
        $mobile={!isTablet}
        parentUrl={breadcrumbs[0]?.value ?? ''}
        title={reportNameText()}
        secondaryTitle={headerTimeFrameText ?? ''}
        modalOpen={modalOpen}
      >
        <MainGrid>
          <Header $modalOpen={modalOpen}>
            <HeaderTitleContainer>
              <Title>{category?.name}</Title>
              <Subtitle>
                {headerTimeFrameText
                  ? `${reportNameText()} (${headerTimeFrameText})`
                  : reportNameText()}
              </Subtitle>
            </HeaderTitleContainer>
            <HeaderAmount value={category?.profitAndLoss ?? 0} />
            {showRoc && (
              <RowContainer>
                <ROCTitle>ROC</ROCTitle>
                <ChangePercentage value={category?.percentChange ?? 0} />
              </RowContainer>
            )}
          </Header>
          {isTablet && (
            <Navigation
              activeLink={url}
              breadcrumbs={breadcrumbs}
              links={navLinks}
            />
          )}
          <Content>
            <Toolbar>
              <TitleTable>{category?.name}</TitleTable>
              {!isTablet && (
                <SortMobileButton
                  color="secondary"
                  onClick={handle.openModal}
                  size="small"
                >
                  Sort
                </SortMobileButton>
              )}
            </Toolbar>
            {category && (
              <ReportCategoryDetailTable
                assets={category.assets}
                isSinceInception={report?.name === 'Since Inception'}
                handleSortByColumn={handleSortByColumn}
                sortApplied={{
                  titleColumn: sortColumn,
                  orderOrientation: sortOrientation as OrderOrientation,
                }}
                headerColumns={headerColumns}
              />
            )}
          </Content>
        </MainGrid>
        {modalOpen && (
          <Formik
            initialValues={initialValues}
            onSubmit={handle.submitSortForm}
            enableReinitialize
          >
            <Form>
              <SortModal onClose={handle.closeModal}>
                <>
                  <ModalTitle>Sort by</ModalTitle>
                  <SortItemsGroup options={sortOptions} name="value" />
                </>
              </SortModal>
            </Form>
          </Formik>
        )}
      </Container>
    </>
  );
};

export default ReportCategoryDetail;
