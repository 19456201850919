export enum OrderOrientation {
  ASCENDENT = 'ASC',
  DESCENDENT = 'DESC',
}

export type KeySortOfColumn = {
  titleColumn: string;
  keyOrderAsc: string;
  keyOrderDesc: string;
};

export type SortApplied = {
  titleColumn: string;
  orderOrientation: OrderOrientation;
};
