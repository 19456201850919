import { FC, memo, useMemo } from 'react';

import Period from 'collections/enums';
import from from 'styles/responsive';
import useMedia from 'utils/useMedia';

import DesktopVersion from './DesktopVersion';
import MobileVersion from './MobileVersion';
import type { Props } from './types';

const startBalanceTitle = (
  period: Props['selectedPeriod'],
  isDefaultPeriod: boolean,
) => {
  if (!isDefaultPeriod) {
    return 'Beg. of Period';
  }

  switch (period) {
    case Period.MONTHLY:
      return 'Beg. of Month';
    case Period.YEARLY:
      return 'Beg. of Year';
  }

  return '';
};

const endBalanceTitle = (
  period: Props['selectedPeriod'],
  isDefaultPeriod: boolean,
) => {
  if (!isDefaultPeriod) {
    return 'End of Period';
  }

  switch (period) {
    case Period.MONTHLY:
      return 'Month to Date';
    case Period.YEARLY:
      return 'Year to Date';
  }

  return '';
};

const AssetClassBreakdownTable: FC<Props> = ({
  data,
  selectedPeriod = Period.MONTHLY,
  isDefaultPeriod,
}) => {
  const headers = useMemo(
    () => [
      {
        id: 'name',
        label: 'Asset Class',
      },
      {
        id: 'activity',
        label: 'Activity',
      },
      {
        id: 'startBalance',
        label: startBalanceTitle(selectedPeriod, isDefaultPeriod),
      },
      {
        id: 'startPortfolioPercentage',
        label: 'Port. %',
      },
      {
        id: 'changedAmount',
        label: 'P&L ($)',
      },
      {
        id: 'changedPercentage',
        label: 'P&L (%)',
      },
      {
        id: 'endBalance',
        label: endBalanceTitle(selectedPeriod, isDefaultPeriod),
      },
      {
        id: 'endPortfolioPercentage',
        label: 'Port. %',
      },
    ],
    [selectedPeriod, isDefaultPeriod],
  );

  const isDesktop = useMedia(from.laptop.query);
  const isTablet = useMedia(from.tablet.query);

  if (isDesktop || isTablet) {
    return <DesktopVersion data={data} headers={headers} />;
  }

  return <MobileVersion data={data.slice(0, -3)} />;
};

export default memo(AssetClassBreakdownTable);
