import { useState } from 'react';

import Period from 'collections/enums';

import type { Data } from './Controls/types';

export default function useConnect() {
  const [controls, setControls] = useState<{
    data: Data;
    period: Period;
  }>({
    data: 'activity',
    period: Period.MONTHLY,
  });

  return {
    controls,
    handle: {
      changeControls: setControls,
    },
  };
}
