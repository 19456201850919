import { FC, useState } from 'react';

import Period from 'collections/enums';
import MarketOverviewTable from 'components/MarketOverviewTable';

import type { Props } from '../types';
import useConnect from './connect';
import { Container, Content, MainTabs, Navigation, TableTabs } from './styles';

const mainTabs = [
  { value: 'watchlist', label: 'Watchlist' },
  { value: 'browse', label: 'Browse' },
];

const tableTabs = [
  { label: 'Daily Change', value: Period.DAILY },
  { label: 'MTD', value: Period.MONTHLY },
  { label: 'YTD', value: Period.YEARLY },
];

const MarketsMobile: FC<Props> = ({
  watchlistData,
  handleChangeMainTab,
  activeMainTab,
}) => {
  const { handle, links } = useConnect();

  const [activeTableTab, setActiveTableTab] = useState<Period>(Period.DAILY);

  return (
    <Container>
      {activeMainTab && (
        <MainTabs
          value={activeMainTab}
          tabs={mainTabs}
          onChange={handleChangeMainTab}
        />
      )}
      {activeMainTab === 'watchlist' ? (
        <Content>
          <TableTabs
            value={activeTableTab}
            tabs={tableTabs}
            onChange={setActiveTableTab}
            variant="bg-tabs"
          />
          <MarketOverviewTable
            data={watchlistData}
            onPin={handle.addToWatchlist}
            onUnpin={handle.removeFromWatchlist}
            selectedData={activeTableTab}
          />
        </Content>
      ) : (
        <Navigation activeLink="/categories/watchlist" links={links} />
      )}
    </Container>
  );
};
export default MarketsMobile;
